import { CarryOutOutlined } from '@ant-design/icons';
import ApplicationConstants from '../../application-constants';
import { AvailabilityPage } from './availability.page';

export const AvailabilityRoutes = {
    path: '/availability',
    element: <AvailabilityPage />,
    icon: <CarryOutOutlined />,
    // subRoutes: [
    //     {
    //         path: 'show-availabilities',
    //         element: <AvailabilityPage />,
    //         roles: [ApplicationConstants.Roles.AllRoles],
    //     },
    // ],
    roles: [ApplicationConstants.Roles.AllRoles],
};
