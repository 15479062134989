export class ImageUtility {
    static resizeImage = (
        file: File,
        width: number,
        height: number,
        quality = 0.75
    ): Promise<File> => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                const fileType = file.type;
                reader.onload = (e) => {
                    const img = new Image();
                    img.src = e.target?.result as string;

                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = width;
                        canvas.height = height;

                        const ctx = canvas.getContext('2d');
                        ctx?.drawImage(img, 0, 0, width, height);

                        // Convert the canvas to a Blob
                        canvas.toBlob(
                            (blob) => {
                                if (blob) {
                                    // Create a File object from the Blob
                                    const resizedImage = new File([blob], `resized_${file.name}`, {
                                        type: fileType,
                                        lastModified: Date.now(),
                                    });
                                    resolve(resizedImage);
                                }
                            },
                            fileType,
                            quality
                        );
                    };
                };
            } catch (error) {
                console.error('Error resizing image:', error);
                reject(error);
            }
        });
    };
}
