import { ContactsOutlined, FieldTimeOutlined } from '@ant-design/icons';

import { Outlet } from 'react-router';
import ApplicationConstants from '../../application-constants';
import { ApplicationRouteType } from '../../routes/route.types';
import { ReservationPage } from './pages/all/reservation.page';
import { ReservationCreatePage } from './pages/create/reservation-create.page';

export const ReservationRoutes: ApplicationRouteType = {
    path: '/reservations',
    element: <Outlet />,
    icon: <ContactsOutlined />,
    roles: [ApplicationConstants.Roles.AllRoles],
    subRoutes: [
        {
            path: '/overview',
            icon: <FieldTimeOutlined />,
            element: <ReservationPage />,
            roles: [ApplicationConstants.Roles.AllRoles],
        },
        {
            path: '/create',
            icon: <FieldTimeOutlined />,
            element: <ReservationCreatePage />,
            roles: [ApplicationConstants.Roles.AllRoles],
            isNotMenuItem: true,
        },
    ],
};
