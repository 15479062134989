import { PropsWithChildren, createContext, useContext } from 'react';
import { useSubscribableState } from '../observable/useSubscribableState';
import type { ServerControllerType } from './server.controller';

interface ServerControllerProps {
    serverController: ServerControllerType;
}
export const ServerControllerContext = createContext<ServerControllerType>(null as any);
export const ServerContextProvider = ({
    children,
    serverController,
}: PropsWithChildren<ServerControllerProps>) => {
    return (
        <ServerControllerContext.Provider value={serverController}>
            {children}
        </ServerControllerContext.Provider>
    );
};

export const useAuthApi = () => useContext(ServerControllerContext).Auth;
export const useUserState = () => {
    const authApi = useAuthApi();
    return useSubscribableState(authApi.subscribable);
};

export const useApi = () => useContext(ServerControllerContext);
export const useReservationApi = () => useContext(ServerControllerContext).Reservation;
export const useServicesApi = () => useContext(ServerControllerContext).Services;

export const useWaitingListApi = () => useContext(ServerControllerContext).WaitingList;
export const useBackofficeUserApi = () => useContext(ServerControllerContext).BackofficeUser;
export const usePublicUserApi = () => useContext(ServerControllerContext).PublicUser;
export const useShiftApi = () => useContext(ServerControllerContext).Shift;
export const useAvailabilityApi = () => useContext(ServerControllerContext).Availability;
export const useSubscribableAvailabilities = () => {
    const api = useAvailabilityApi();
    const state = useSubscribableState(api.subscribable);
    return state;
};
export const useUserServiceApi = () => useContext(ServerControllerContext).UserService;

export const useAllApis = () => useContext(ServerControllerContext);
