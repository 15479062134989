import { AxiosError } from 'axios';

export interface ErrorResDto<T = any> {
    errors: T;
}

export const IsErrorResDto = function IsErrorResDto<T>(
    unknownType: any
): unknownType is ErrorResDto<T> {
    return unknownType && 'errors' in unknownType && Object.keys(unknownType.errors).length > 0;
};

export const HandleAxiosErrorResDto = function HandleAxiosErrorResDto(err: unknown) {
    const error = err as AxiosError<ErrorResDto, any>;
    if (error.response) {
        return error.response.data;
    }
    return {
        statusCode: 500,
        errors: 'InternalServerError',
    };
};
