import axios from 'axios';
import { ENV } from '../../env';
import type { GetUsersResponseDto } from '../user/res/get-user-res.dto';
import type { GetUsersShiftResponseDto } from '../user/res/get-user-shift-res.dto';
import type { PatchShiftRequestDto } from './req/patch-shift-req.dto';
import type { PostShiftRequestDto } from './req/post-shift-req.dto';
import type { PostShiftResponse } from './res/post-shift-response';

export class ShiftApi {
    private readonly Url = `${ENV.API_URL}/api/shift`;

    getOne(userId: string) {
        const params = new URLSearchParams();
        params.append('userId', userId);
        return axios.get<GetUsersShiftResponseDto[]>(this.Url, { params });
    }
    get() {
        return axios.get<GetUsersResponseDto[]>(this.Url);
    }

    post(dto: PostShiftRequestDto) {
        return axios.post<PostShiftResponse[]>(this.Url, dto);
    }
    patch(dto: PatchShiftRequestDto) {
        return axios.patch(this.Url, dto);
    }
    delete(id: string) {
        const params = new URLSearchParams();
        params.append('id', id);
        return axios.delete(this.Url, { params });
    }
}
