import { Input } from 'antd';
import { ChangeEventHandler, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReservationFilterContext } from './reservation-filters.context';
import { GetReservationRequestType } from './reservation-type-filter.component';
type Props = { style?: React.CSSProperties; className?: string };

let timer: NodeJS.Timeout | null = null;
export const ReservationSearchFilterComponent = (props: Props) => {
    const { setFilterState } = useContext(ReservationFilterContext);
    const { t } = useTranslation();
    const handleSearch = (value: string) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            setFilterState((x) => {
                return {
                    search: value,
                    reservationType: GetReservationRequestType.ALL,
                    dateRange: {
                        from: undefined,
                        to: undefined,
                    },
                    isLoading: true,
                    selectedDayValue: undefined,
                    selectedWeekValue: undefined,
                };
            });
        }, 500);
    };

    const onChangeDebounce: ChangeEventHandler<HTMLInputElement> = (value) => {
        handleSearch(value.currentTarget.value);
    };

    return (
        <Input.Search
            {...props}
            onChange={onChangeDebounce}
            placeholder={t`Search` as string}
            onSearch={handleSearch}
            enterButton
        />
    );
};
