import { MenuProps } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useUserState } from '../api/server.controller.context';
import { GetRoutesBasedOfRole } from './route-definitions';
import { ApplicationRouteType } from './route.types';

export type CustomMenuItem = Required<MenuProps>['items'][number];

export const MainLayoutItems = (className?: string) => {
    const { role } = useUserState();
    const navigateTo = useNavigate();
    const { t } = useTranslation();

    const mapToMenuItem = useCallback(
        (x: ApplicationRouteType, parentPath = ''): CustomMenuItem => {
            const label = parentPath + x.path;
            let localizedLabel = t(label);

            const key = x.path;
            const icon = x.icon || '';

            const children = x?.subRoutes
                ?.filter((child) => !child.isNotMenuItem)
                ?.map((child) => mapToMenuItem(child, x.path));
            if (children) {
                return {
                    key,
                    icon,
                    children,
                    label: localizedLabel,
                    title: localizedLabel,
                } as CustomMenuItem;
            }
            return {
                key,
                icon,
                children,
                label: localizedLabel,
                title: localizedLabel,
                className,
                onClick: (x) => {
                    const reverse = x.keyPath.reverse();
                    navigateTo(reverse.join(''));
                },
            } as CustomMenuItem;
        },
        [t, navigateTo, className]
    );
    const routes = useMemo(() => {
        const routesToUse = GetRoutesBasedOfRole(role).filter((x) => !x.isNotMenuItem);
        return routesToUse;
    }, [role]);

    const mappedMenuItems: CustomMenuItem[] = useMemo(
        () => routes.map((x) => mapToMenuItem(x)),
        [routes, mapToMenuItem]
    );

    return mappedMenuItems;
};
