import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { GetAvailabilitiesResDto } from '../../../api/availability/res/get-availabilities-res.dto';
import { BarberColorTagComponent } from '../../../components/barber/barber-color-tag.component';
import { StringUtility } from '../../../utils/StringUtility';

type Props = {
    dataSource?: GetAvailabilitiesResDto[];
    onRow?: (
        data: GetAvailabilitiesResDto,
        index?: number
    ) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>;
    config?: {
        hideCreateReservationButton?: boolean;
    };
};
export const AvailabilityTableComponent = (props: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const columns: ColumnsType<GetAvailabilitiesResDto> = [
        {
            title: t`Employee`,
            width: 5,
            render(a: string, availability: GetAvailabilitiesResDto) {
                return (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        <BarberColorTagComponent barber={availability.barber} />
                    </div>
                );
            },
            ellipsis: true,
        },
        {
            title: t`Date`,
            width: 5,
            render(_: string, record: GetAvailabilitiesResDto) {
                return (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        {StringUtility.asAbsoluteLocalDateString(i18n.language, record.start)}
                    </div>
                );
            },
            ellipsis: true,
        },
        {
            title: t`Time`,
            width: 5,
            ellipsis: true,
            render(_: string, record: GetAvailabilitiesResDto) {
                return (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                        <span>
                            {StringUtility.asAbsoluteLocalTimeString(i18n.language, record.start)}
                        </span>
                        &nbsp;-&nbsp;
                        <span>
                            {StringUtility.asAbsoluteLocalTimeString(i18n.language, record.end)}
                        </span>
                    </div>
                );
            },
        },
    ];

    return (
        <Table
            onRow={props.onRow}
            rowClassName={'cursor-pointer'}
            rowKey={(x) => x.shiftId}
            dataSource={props.dataSource ?? []}
            loading={!Boolean(props.dataSource)}
            columns={columns}
        />
    );
};
