import { Button } from 'antd';
import { EmojiPicker, EmojiRecord, createPicker } from 'picmo';
import { memo, useEffect, useRef } from 'react';

type Props = {
    onChange?: (emoji: string) => void;
    value?: string;
};

export const EmojiComponent = memo((props: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const emojiPicker = useRef<EmojiPicker>();
    const currentPickedEmojis = useRef<string>();
    if (props.value) {
        currentPickedEmojis.current = props.value;
    }
    useEffect(() => {
        if (!ref.current || emojiPicker.current) {
            return;
        }
        emojiPicker.current = createPicker({
            rootElement: ref.current,
            showPreview: false,
            visibleRows: 3,
            emojisPerRow: 14,
            emojiSize: '1rem',
        });
        emojiPicker.current.addEventListener('emoji:select', (emojiRecord: EmojiRecord) => {
            if (props.value) {
            }
            if (!currentPickedEmojis?.current) {
                currentPickedEmojis.current = '';
            }
            if (currentPickedEmojis.current?.includes(emojiRecord.emoji)) {
                return;
            }
            currentPickedEmojis.current += emojiRecord.emoji;
            props?.onChange?.(currentPickedEmojis.current);
        });
        return () => emojiPicker?.current?.removeEventListener('emoji:select', () => {});
    }, [props]);
    return (
        <>
            <div ref={ref}></div>
            <div>
                <span>{currentPickedEmojis.current}</span>
                {currentPickedEmojis.current && (
                    <Button
                        onClick={(x) => {
                            currentPickedEmojis.current = '';
                            props?.onChange?.('');
                        }}
                    >
                        X
                    </Button>
                )}
            </div>
        </>
    );
});
