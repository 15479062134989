export class IntlUtility {
    static formatAsMinute(language: string, number: number) {
        return Intl.NumberFormat(language, {
            style: 'unit',
            unit: 'minute',
            unitDisplay: 'short',
        }).format(number);
    }

    static formatAsClock(language: string, number: number) {
        return Intl.DateTimeFormat(language, {
            timeStyle: 'medium',
        }).format(number);
    }
    static formatAsDate(language: string, date: Date) {
        return new Intl.DateTimeFormat(language, {
            weekday: 'long',
            day: '2-digit',
            month: '2-digit',
        }).format(date);
    }
    static formatAsTime(language: string, date: Date) {
        return new Intl.DateTimeFormat(language, {
            hour: '2-digit',
            minute: '2-digit',
        }).format(date);
    }

    static formatAsDateTime(language: string, date: Date) {
        return new Intl.DateTimeFormat(language, {
            weekday: 'long',
            day: '2-digit',
            month: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        }).format(date);
    }
}
