import axios from 'axios';
import { ENV } from '../../env';
import type { PatchServicesRequestDto } from './req/patch-services-req.dto';
import type { PostServicesRequestDto } from './req/post-services-req.dto';
import type { GetProvidedServiceResponseDto } from './res/get-provided-service-res.dto';

export class ServicesApi {
    private readonly Url = `${ENV.API_URL}/api/services`;

    get() {
        return axios.get<GetProvidedServiceResponseDto[]>(this.Url);
    }

    post(dto: PostServicesRequestDto) {
        const formData = new FormData();
        if (dto.image) {
            formData.append('image', dto.image);
        }
        formData.append('name', dto.name);
        formData.append('duration', dto.duration.toString());
        if (dto.translation) {
            formData.append('translation', dto.translation);
        }
        if (dto.emoji) {
            formData.append('emoji', dto.emoji);
        }
        if (dto.providedServiceGroupId) {
            formData.append('providedServiceGroupId', dto.providedServiceGroupId);
        }
        return axios.post(this.Url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
    patch(dto: PatchServicesRequestDto) {
        const formData = new FormData();
        formData.append('id', dto.id);

        if (dto.imageBytes) {
            formData.append('image', dto.imageBytes);
        }
        formData.append('name', dto.name);
        formData.append('duration', dto.duration.toString());
        if (dto.translation) {
            formData.append('translation', dto.translation);
        }
        if (dto.emoji) {
            formData.append('emoji', dto.emoji);
        }
        if (dto.providedServiceGroup) {
            formData.append('providedServiceGroup', dto.providedServiceGroup);
        }
        return axios.patch(this.Url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    delete(id: string) {
        const params = new URLSearchParams();
        params.append('id', id);
        return axios.delete(this.Url, { params });
    }
}
