import { PicRightOutlined } from '@ant-design/icons';
import ApplicationConstants from '../../application-constants';
import { ApplicationRouteType } from '../../routes/route.types';
import { ServicesPage } from './services.page';

export const ServicesRoutes: ApplicationRouteType = {
    path: '/services',
    element: <ServicesPage />,
    icon: <PicRightOutlined />,
    roles: [ApplicationConstants.Roles.BackofficeAdministrator],
};
