import { Button } from 'antd';
import { useAuthApi } from '../api/server.controller.context';

type Props = {};

export const LogoutButtonComponent = (props: Props) => {
    const authApi = useAuthApi();
    return (
        <Button type="primary" onClick={authApi.logout}>
            Logout
        </Button>
    );
};
