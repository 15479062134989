import { useCallback, useEffect, useState } from 'react';
import { useApi } from '../../api/server.controller.context';
import { GetServiceGroupResponse } from '../../api/service-group/res/get-provided-service-res.dto';
import { GetProvidedServiceResponseDto } from '../../api/services/res/get-provided-service-res.dto';
import { ProvidedServiceFormModal } from './components/services-create.component';
import { ProvidedServiceTableComponent } from './components/services-table.component';

type Props = {};

export const ServicesPage = (props: Props) => {
    const [servicesState, setServicesState] = useState<GetProvidedServiceResponseDto[]>();
    const [serviceGroupState, setServiceGroupState] = useState<GetServiceGroupResponse[]>();
    const api = useApi();
    const load = useCallback(async () => {
        const services = await api.Services.get();
        setServicesState(services.data);
        const groups = await api.ServiceGroup.get();
        setServiceGroupState(groups.data);
    }, [api]);
    useEffect(() => {
        load();
    }, [load]);

    return (
        <>
            <ProvidedServiceFormModal reload={load} />
            <ProvidedServiceTableComponent
                services={servicesState}
                reload={load}
                serviceGroups={serviceGroupState}
            />
        </>
    );
};
