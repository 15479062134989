import { Button, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthPostReqDto } from '../../api/auth/req/auth-post-req.dto';
import { useAuthApi } from '../../api/server.controller.context';

export const LoginPage: React.FC = () => {
    const authApi = useAuthApi();
    const { t } = useTranslation();
    const onFinish = async (values: AuthPostReqDto) => {
        await authApi.login(values);
    };

    const onFinishFailed = (errorInfo: any) => {};

    return (
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Username"
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    {t`Login`}
                </Button>
            </Form.Item>
        </Form>
    );
};
