import { useCallback, useContext, useEffect, useState } from 'react';
import { GetAvailabilitiesResDto } from '../../../api/availability/res/get-availabilities-res.dto';
import { useAvailabilityApi } from '../../../api/server.controller.context';
import { DateUtility } from '../../../utils/DateUtility';
import { AvailabilityFilterContext } from '../availability-filters.context';
import { AvailabilityTableComponent } from './availability-table.component';

type Props = {
    onRow?: (
        data: GetAvailabilitiesResDto,
        index?: number
    ) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>;
};
export const AvailabilityTableFetchComponent = (props: Props) => {
    const { filterState } = useContext(AvailabilityFilterContext);

    const availabilityApi = useAvailabilityApi();
    const [dataSource, setDataSource] = useState<GetAvailabilitiesResDto[] | undefined>();
    const load = useCallback(async () => {
        const response = await availabilityApi.get({
            serviceIds: filterState.serviceIds,
            from: DateUtility.asAbsoluteUtcString(filterState.dateRange.from),
            to: DateUtility.asAbsoluteUtcString(filterState.dateRange.to),
            barberId: filterState.barberId,
        });
        setDataSource(response.data);
    }, [availabilityApi, filterState]);

    useEffect(() => {
        load();
    }, [load]);

    return <AvailabilityTableComponent {...props} dataSource={dataSource} />;
};
