import { useTranslation } from 'react-i18next';

type Props = {};

export const CreatedByComponent = (props: Props) => {
    const { t } = useTranslation();
    return (
        <a href="https://continium-labs.com" target="_blank" rel="noreferrer">
            {t`createdBy`} Continium Labs
        </a>
    );
};
