import { FileImageFilled } from '@ant-design/icons';
import {
    Avatar,
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
    Typography,
    message,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi, useServicesApi } from '../../../api/server.controller.context';
import type { GetServiceGroupResponse } from '../../../api/service-group/res/get-provided-service-res.dto';
import { PostServicesRequestDto } from '../../../api/services/req/post-services-req.dto';
import { ImageUtility } from '../../../utils/ImageUtility';
import { StringUtility } from '../../../utils/StringUtility';
type Props = {
    reload?: () => any;
};

const { Title } = Typography;
export const ProvidedServiceFormModal = (props: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const handleModalOpen = () => {
        setModalVisible(true);
    };
    const api = useApi();

    const [groupState, setGroupState] = useState<GetServiceGroupResponse[]>();

    useEffect(() => {
        const loadGroup = async () => {
            const groups = await api.ServiceGroup.get();
            setGroupState(groups.data);
        };
        loadGroup();
    }, [api]);

    const handleModalClose = () => {
        form.resetFields();
        setModalVisible(false);
    };
    const [form] = useForm<PostServicesRequestDto>();
    const [file, setFile] = useState<File>();
    const [base64Image, setBase64Image] = useState<string>();

    const { t } = useTranslation();
    const servicesApi = useServicesApi();

    const handleAddService = async () => {
        try {
            const values = await form.validateFields();
            values.image = file;
            await servicesApi.post(values);
            setModalVisible(false);
            props?.reload?.();
        } catch (error) {}
    };

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) {
            message.error('File not uploaded, please try again..');
            return;
        }
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            return;
        }
        const isLessThen5M = file.size / 1024 / 1024 < 5;
        if (!isLessThen5M) {
            message.error('Image must smaller than 5MB!');
            return;
        }
        const resizedFile = await ImageUtility.resizeImage(file, 261, 250, 1);
        setFile(resizedFile);
        StringUtility.toBase64String(resizedFile, (base64) => {
            setBase64Image(base64);
        });
    };

    return (
        <>
            <Row
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                className="my-3 me-2"
            >
                <Col>
                    <Title level={2}>{t`Services list`}</Title>
                </Col>
                <Col>
                    <Button type="primary" onClick={handleModalOpen}>
                        {t`Create New`}
                    </Button>
                </Col>
            </Row>
            <Modal
                destroyOnClose
                open={modalVisible}
                onCancel={handleModalClose}
                footer={[
                    <Button key="cancel" onClick={handleModalClose}>
                        {t`Cancel`}
                    </Button>,
                    <Button key="add" type="primary" onClick={handleAddService}>
                        {t`Save`}
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label={t`Name` as string}
                        rules={[
                            {
                                required: true,
                                message: t`Name is required` as string,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="translation"
                        label={t`Translation` as string}
                        rules={[
                            {
                                warningOnly: true,
                                message: t`Translation is recommended` as string,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="duration"
                        label={t`Duration` as string}
                        rules={[
                            {
                                required: true,
                                message: t`Duration is required` as string,
                            },
                        ]}
                    >
                        <InputNumber addonAfter={t`Minutes`} min={1} step={1} max={2147483647} />
                    </Form.Item>

                    <Form.Item
                        name="providedServiceGroupId"
                        label={t`Group` as string}
                        rules={[
                            {
                                required: true,
                                message: t`Group is required` as string,
                            },
                        ]}
                    >
                        <Select
                            options={groupState?.map((x) => ({
                                value: x.id,
                                key: x.id,
                                label: t(x.name),
                            }))}
                        />
                    </Form.Item>
                    <Form.Item name="group"></Form.Item>
                    <Form.Item name="file" label={t`File` as string}>
                        <>
                            <label htmlFor="file" className="cursor-pointer">
                                {!base64Image ? (
                                    <Avatar
                                        shape="square"
                                        size={64}
                                        icon={<FileImageFilled />}
                                    ></Avatar>
                                ) : (
                                    <Avatar shape="square" size={64} src={base64Image}></Avatar>
                                )}
                            </label>
                            <input
                                type="file"
                                id="file"
                                name="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                hidden
                                className="d-none"
                            />
                        </>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
