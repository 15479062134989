import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ApplicationConstants from '../application-constants';
import { LocalStorageUtils } from '../utils/LocalStorageUtility';
import en from './en.json';
import hr from './hr.json';
let isInitialized = false;
export function initI18n() {
    if (isInitialized) {
        return;
    }
    // the translations
    const resources = {
        en: {
            translation: en,
        },
        hr: {
            translation: hr,
        },
    };

    i18n.use(initReactI18next) // passes i18n down to react-i18next
        .init({
            fallbackLng: 'en',
            resources,
            lng:
                LocalStorageUtils.retrieve<string>(
                    ApplicationConstants.LocalStorage.Keys.SelectedLang
                ) || 'en',
        });
    isInitialized = true;
}

export const GetAndInitI18N = function () {
    initI18n();
    return i18n;
};
