import { Col, Modal, Row, Tabs, TabsProps, Typography } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { GetReservationResDto } from '../../../../api/reservation/res/get-reservation-res.dto';
import { useReservationApi } from '../../../../api/server.controller.context';
import { CenteredSpinnerComponent } from '../../../../components/centered-spinner.component';
import { NotificationHub } from '../../../../signlar/notification.hub';
import { DateUtility } from '../../../../utils/DateUtility';
import { ReservationDateRangeFilterComponent } from '../../components/filters/reservation-date-range-filter.component';
import { ReservationDayFilterComponent } from '../../components/filters/reservation-day-filter.component';
import { ReservationFilterContext } from '../../components/filters/reservation-filters.context';
import { ReservationSearchFilterComponent } from '../../components/filters/reservation-search-filter.component';
import { ReservationRequestTypeFilterComponent } from '../../components/filters/reservation-type-filter.component';
import { ReservationWeekFilterComponent } from '../../components/filters/reservation-week-filter.component';
import {
    ReservationCalendarComponent,
    type RangeChangeProps,
} from '../../components/reservation-calendar.component';
import { ReservationTableComponent } from '../../components/reservation-table.component';
import { ReservationEditPage } from '../edit/reservation-edit.page';

const { Title } = Typography;

const calendarView = 'calendarView';
const tableView = 'tableView';
type Props = {
    onEditClick?: (data: GetReservationResDto) => void;
    publicUserId?: string;
};

export const ReservationContextPage = (props: Props) => {
    const reservationApi = useReservationApi();
    const [openEdit, setEdit] = useState<GetReservationResDto | undefined>(undefined);
    const [showFilters, setShowFilters] = useState(true);

    const { t } = useTranslation();

    useEffect(() => {
        NotificationHub.on('NewPublicReservation', (_: GetReservationResDto) => {
            try {
                setFilterState((x) => ({ ...x }));
            } finally {
            }
        });
        return () => {};
    }, []);
    const [dataSource, setDataSource] = useState<GetReservationResDto[]>();
    const { filterState, setFilterState } = useContext(ReservationFilterContext);
    const location = useLocation();

    const [currentDate, setCurrentDate] = useState<Date | undefined>(new Date());

    const defaultActiveKey = useMemo(() => {
        return calendarView;
        return location.hash?.includes(`#${calendarView}`) ? calendarView : tableView;
    }, [location]);

    const onRangeChange: RangeChangeProps = (range, view, currentDate) => {
        setCurrentDate(currentDate);
        let from: Date, to: Date;
        if (Array.isArray(range)) {
            from = to = range[0];
        } else {
            from = range.start;
            to = range.end;
        }

        setFilterState((x) => {
            return {
                ...x,
                dateRange: {
                    from: dayjs(from),
                    to: dayjs(to),
                },
            };
        });
    };

    const items: TabsProps['items'] = [
        {
            key: tableView,
            label: t`Table View`,
            children: (
                <ReservationTableComponent
                    dataSource={dataSource}
                    onDeleteConfirmed={async (x) => {
                        await reservationApi.delete(x.id);
                        setFilterState((x) => ({ ...x }));
                    }}
                    onEditClicked={(state) => {
                        setEdit(state);
                        props?.onEditClick?.(state);
                    }}
                />
            ),
        },
        {
            key: calendarView,
            label: t`Calendar View`,
            children: (
                <>
                    {!dataSource ? (
                        <CenteredSpinnerComponent />
                    ) : (
                        <ReservationCalendarComponent
                            dataSource={dataSource}
                            onRangeChange={onRangeChange}
                            currentDate={currentDate}
                            onEventClick={(e) => setEdit(e)}
                            onDeleteCompleted={(resource) => {
                                const indexOf = dataSource?.indexOf(resource);
                                if (indexOf >= 0) {
                                    setDataSource((x) => {
                                        if (x) {
                                            x?.splice(indexOf ?? -1, 1);

                                            return [...x];
                                        }
                                    });
                                }
                            }}
                        />
                    )}
                </>
            ),
        },
    ];

    const load = useCallback(async () => {
        setDataSource(undefined);
        const response = await reservationApi.get({
            ...filterState,
            publicUserId: props.publicUserId,
            dateRange: {
                from:
                    filterState.dateRange.from &&
                    DateUtility.asAbsoluteUtcString(filterState.dateRange.from),
                to:
                    filterState.dateRange.to &&
                    DateUtility.asAbsoluteUtcString(filterState.dateRange.to),
            },
        });
        setDataSource(response.data);
    }, [reservationApi, filterState, props.publicUserId]);
    useEffect(() => {
        if (defaultActiveKey === 'calendarView') {
            setShowFilters(false);
        }
        load();
    }, [load]);

    return (
        <>
            <Modal
                open={Boolean(openEdit)}
                onCancel={(x) => setEdit(undefined)}
                footer={false}
                destroyOnClose
            >
                <ReservationEditPage
                    data={openEdit}
                    onEditFinished={() => {
                        setEdit(undefined);
                        load();
                    }}
                />
            </Modal>

            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Col>
                    <Title level={2}>{t`Reservation List`}</Title>
                </Col>
                {showFilters && (
                    <Col>
                        <Row
                            style={{
                                alignItems: 'center',
                            }}
                            gutter={12}
                        >
                            <Col xs={12} className="mt-1">
                                <ReservationDayFilterComponent style={{ width: '100%' }} />
                            </Col>

                            <Col xs={12} className="mt-1">
                                <ReservationWeekFilterComponent style={{ width: '100%' }} />
                            </Col>

                            <Col xs={12} className="mt-1">
                                <ReservationRequestTypeFilterComponent style={{ width: '100%' }} />
                            </Col>

                            <Col xs={12} className="mt-1">
                                <ReservationSearchFilterComponent style={{ width: '100%' }} />
                            </Col>
                            <Col xs={24} className="mt-1">
                                <ReservationDateRangeFilterComponent style={{ width: '100%' }} />
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
            <Tabs
                defaultActiveKey={defaultActiveKey}
                items={items}
                onChange={(x) => {
                    if (x === 'calendarView') {
                        setShowFilters(false);
                    } else {
                        setShowFilters(true);
                    }
                }}
            />
        </>
    );
};
