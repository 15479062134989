import { ColorPicker, Form, Input, Select } from 'antd';
import { Rule } from 'antd/es/form';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../api/server.controller.context';
import { GetProvidedServiceResponseDto } from '../../../api/services/res/get-provided-service-res.dto';
import {
    AccountTypeEnum,
    PostBackofficeUserReqDto,
} from '../../../api/user/req/post-backoffice-user-req.dto';
import { GetUsersResponseDto } from '../../../api/user/res/get-user-res.dto';
import { AvailabilityServicesMultiselectComponent } from '../../availability/components/availability-services-multiselect.component';

interface UserCreationFormProps {
    onSubmitAsync: (
        values: Partial<GetUsersResponseDto> & { confirmPassword: string; password: string }
    ) => Promise<void> | void;
    editableEntity?: GetUsersResponseDto;
}
type FormType = Partial<PostBackofficeUserReqDto> & {
    colorString: { toHexString: () => string };
};
export const CreateBackofficeUserFormComponent: React.FC<UserCreationFormProps> = ({
    onSubmitAsync,
    editableEntity,
}) => {
    const [form] = Form.useForm<FormType>();
    const { t } = useTranslation();
    const api = useApi();

    const onFinish = async (values: FormType) => {
        await onSubmitAsync({
            accountType: values.accountType!,
            confirmPassword: values.confirmPassword!,
            firstName: values.firstName!,
            lastName: values.lastName!,
            password: values.password!,
            serviceIds: values.serviceIds!,
            userName: values.username!,
            email: values.email!,
            colorString: values.colorString?.toHexString?.() || (values.colorString as any),
        });
        //form.resetFields();
    };
    let initialValues: FormType = {
        accountType: undefined,
        colorString: undefined as any,
        confirmPassword: undefined,
        email: undefined,
        firstName: undefined,
        lastName: undefined,
        password: undefined,
        serviceIds: undefined,
        username: undefined,
    };

    if (editableEntity) {
        initialValues = {
            firstName: editableEntity.firstName,
            lastName: editableEntity.lastName,
            email: editableEntity.email,
            password: '',
            confirmPassword: '',
            serviceIds: editableEntity.serviceIds,
            username: editableEntity.userName,
            accountType: editableEntity.accountType,
            colorString: editableEntity.colorString as any,
        };
    }

    const [servicesState, setServicesState] = useState<GetProvidedServiceResponseDto[]>();
    const load = useCallback(async () => {
        const services = await api.Services.get();
        setServicesState(services.data);
    }, [api]);
    const [passwordRules, setPasswordRules] = useState<Rule[]>([]);
    const [confirmPasswordRules, setConfirmPasswordRules] = useState<Rule[]>([]);
    const confirmPasswordValidator = (_: any, value: string) => {
        const password = form.getFieldValue('password');
        if (password !== value) {
            return Promise.reject('Passwords do not match');
        }
        return Promise.resolve();
    };
    const confirmPasswordRulesInitial = [
        { required: true, message: t`Please confirm the password` as string },
        { validator: confirmPasswordValidator },
    ];
    const passwordRulesInitial = [
        { required: true, message: t`Please enter the password` as string },
        { min: 8, message: t`Password must be at least 8 characters long` as string },
    ];
    useEffect(() => {
        if (!editableEntity?.id) {
            setConfirmPasswordRules(confirmPasswordRulesInitial);
            setPasswordRules(passwordRulesInitial);
        }
        load();
    }, [load, editableEntity]);

    return (
        <Form
            form={form}
            onFieldsChange={(changedFields, fields) => {
                const passwordChanged = fields.find(
                    (x) =>
                        (x.name.toString().includes('password') ||
                            x.name.toString().includes('confirmPassword')) &&
                        x.value
                );
                if (passwordChanged) {
                    setConfirmPasswordRules([...confirmPasswordRulesInitial]);
                    setPasswordRules([...passwordRulesInitial]);
                } else {
                    setConfirmPasswordRules([]);
                    setPasswordRules([]);
                }
            }}
            onFinish={onFinish}
            layout="vertical"
            id="form"
            name="form"
            initialValues={initialValues}
        >
            <Form.Item
                name="username"
                label={t`Username`}
                rules={[{ required: true, message: t`Please enter the username` as string }]}
            >
                <Input placeholder={t`Enter the username` as string} autoComplete="new-password" />
            </Form.Item>
            <Form.Item name="password" label={t`Password`} rules={passwordRules}>
                <Input.Password
                    placeholder={t`Enter the password` as string}
                    autoComplete="new-password"
                />
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                label={t`Confirm Password`}
                dependencies={['password']}
                rules={confirmPasswordRules}
            >
                <Input.Password placeholder={t`Confirm the password` as string} />
            </Form.Item>
            <Form.Item
                name="firstName"
                label={t`First Name`}
                rules={[{ required: true, message: t`Please enter the first name` as string }]}
            >
                <Input placeholder={t`Enter the first name` as string} />
            </Form.Item>
            <Form.Item
                name="lastName"
                label={t`Last Name`}
                rules={[{ required: true, message: t`Please enter the last name` as string }]}
            >
                <Input placeholder={t`Enter the last name` as string} />
            </Form.Item>
            <Form.Item name="email" label={t`Email`}>
                <Input placeholder={t`Enter the email` as string} type="email" />
            </Form.Item>

            {!editableEntity?.id && (
                <Form.Item
                    name="accountType"
                    label={t`Account Type`}
                    rules={[
                        { required: true, message: t`Please select the account type` as string },
                    ]}
                >
                    <Select placeholder={t`Select the account type`}>
                        {Object.keys(AccountTypeEnum)
                            .filter((x) => x !== AccountTypeEnum.PublicSiteUser)
                            .map((value) => (
                                <Select.Option key={value} value={t`${value}`}>
                                    {value}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>
            )}

            <Form.Item
                name="colorString"
                label={t`Color`}
                rules={[{ required: true, message: t`Please pick a color` as string }]}
            >
                <ColorPicker format="hex" defaultValue={undefined} />
            </Form.Item>
            {!editableEntity?.id && (
                <>
                    {' '}
                    <Form.Item hidden name="serviceIds"></Form.Item>{' '}
                    <Form.Item
                        name="serviceNames"
                        label={t`Services`}
                        rules={[{ required: true, message: t`Please pick a service` as string }]}
                    >
                        <AvailabilityServicesMultiselectComponent<GetProvidedServiceResponseDto>
                            services={servicesState || []}
                            keySelector="name"
                            value={editableEntity?.serviceIds || []}
                            onChange={(selectedValues, option, services) => {
                                if (services && services.length) {
                                    form.setFieldsValue({
                                        serviceIds: services.map((x) => x.id),
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                </>
            )}

            {/* <Form.Item>
                <Button type="primary" htmlType="submit">
                    {t`Create User`}
                </Button>
            </Form.Item> */}
        </Form>
    );
};
