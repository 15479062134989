import { PropsWithChildren, createContext, useContext } from 'react';

import type { AppContextConfigType } from './app.config';
import { useSubscribableState } from './observable/useSubscribableState';

interface Props {
    appConfig: AppContextConfigType;
}
export const AppConfigContext = createContext<AppContextConfigType>(null as any);
export const AppConfigContextProvider = ({ children, appConfig }: PropsWithChildren<Props>) => {
    return <AppConfigContext.Provider value={appConfig}>{children}</AppConfigContext.Provider>;
};

export const useSubscribableTheme = () => {
    const context = useContext(AppConfigContext);
    return useSubscribableState(context.Theme.subscribable);
};

export const useAppTheme = () => {
    const context = useContext(AppConfigContext);
    return context.Theme;
};
