import { FieldTimeOutlined } from '@ant-design/icons';
import ApplicationConstants from '../application-constants';
import { WaitingListComponent } from '../components/waiting-list/waiting-list.component';
import { AvailabilityRoutes } from '../pages/availability/availability.routes';
import { ReservationRoutes } from '../pages/reservation/reservation.routes';
import { ServicesRoutes } from '../pages/services/services.routes';
import { UserRoutes } from '../pages/user/user.routes';
import { ApplicationRouteType } from './route.types';

export const RoutesDefinitions: ApplicationRouteType[] = [
    ReservationRoutes,
    UserRoutes,
    AvailabilityRoutes,
    ServicesRoutes,
    {
        path: '/waiting-list',
        icon: <FieldTimeOutlined />,
        element: <WaitingListComponent />,
        roles: [ApplicationConstants.Roles.AllRoles],
    },
];

const includesRole =
    (currentRole: string | string[]) => (applicationRoute: ApplicationRouteType) => {
        const hasSubRoutes = applicationRoute.subRoutes;
        const requiredRoles = applicationRoute.roles;

        const isSuperAdminLoggedIn = currentRole.includes(ApplicationConstants.Roles.SuperAdmin);
        if (isSuperAdminLoggedIn) {
            return true;
        }
        if (!requiredRoles && !hasSubRoutes) {
            return true;
        }
        const isAnyRole = requiredRoles?.includes('*');
        if (isAnyRole) {
            return true;
        }
        if (hasSubRoutes) {
            applicationRoute.subRoutes = applicationRoute.subRoutes?.filter(
                includesRole(currentRole)
            );
            return true;
        }
        return applicationRoute.roles?.some((x) => currentRole.includes(x)) || false;
    };

// export const AdminRoutes = RoutesDefinitions.filter(
//     includesRole(ApplicationConstants.Roles.BackofficeAdministrator)
// );

// export const SuperAdminRoutes = RoutesDefinitions;

// export const EmployeeRoutes = RoutesDefinitions.filter(
//     includesRole(ApplicationConstants.Roles.BackofficeEmployee)
// );

export const GetRoutesBasedOfRole = (role: string | string[]) =>
    RoutesDefinitions.map((x) => {
        const subRoutes = x.subRoutes?.map((x) => x);
        return { ...x, subRoutes };
    }).filter(includesRole(role));
