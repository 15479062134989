import { TeamOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { Outlet } from 'react-router';
import ApplicationConstants from '../../application-constants';
import type { ApplicationRouteType } from '../../routes/route.types';
import { BackofficeUserPage } from './backoffice-user.page';
import { PublicUserPage } from './public-user.page';

export const UserRoutes: ApplicationRouteType = {
    path: '/users',
    element: <Outlet />,
    icon: <UserOutlined />,
    subRoutes: [
        {
            path: '/backoffice-users',
            element: <BackofficeUserPage />,
            icon: <TeamOutlined />,
            roles: [ApplicationConstants.Roles.BackofficeAdministrator],
        },
        {
            path: '/public-users',
            element: <PublicUserPage />,
            icon: <UserSwitchOutlined />,
            roles: [
                ApplicationConstants.Roles.BackofficeAdministrator,
                ApplicationConstants.Roles.BackofficeEmployee,
            ],
        },
    ],
};
