import {
    AppstoreOutlined,
    CarryOutOutlined,
    ContactsOutlined,
    FieldTimeOutlined,
    PicRightOutlined,
    PlusCircleOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import { Button, Col, Drawer, Layout, Menu, Row, notification, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { GetReservationResDto } from '../api/reservation/res/get-reservation-res.dto';
import { useUserState } from '../api/server.controller.context';
import { useSubscribableTheme } from '../app-config.context';
import { ClockComponent } from '../components/clock.component';
import { CreatedByComponent } from '../components/created-by.component';
import { LanguagesDropDownComponent } from '../components/languages-dropdown.component';
import { LogoutButtonComponent } from '../components/logout-button.component';
import { ThemeToggleComponent } from '../components/theme-toggle.component';
import { NotificationHub } from '../signlar/notification.hub';
import { ApplicationRoutes } from './application-routes';
import { MainLayoutItems } from './main-layout-items';

const { Header, Content, Footer, Sider } = Layout;

export const MainLayout: React.FC = () => {
    const checkTheme = useSubscribableTheme();
    const { role } = useUserState();
    const [open, setOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate();

    const { t } = useTranslation();
    const location = useLocation();
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const desktopMenuItems: any[] = MainLayoutItems();

    const locationPathSplit = location.pathname.split('/');

    const [api, contextHolder] = notification.useNotification();
    // const sound = new Audio('/scissor.mp3');

    const selectedKeys =
        locationPathSplit.length > 2 ? [`/${locationPathSplit[2]}`] : [`/${locationPathSplit[1]}`];

    useEffect(() => {
        NotificationHub.on('NewPublicReservation', (_: GetReservationResDto) => {
            try {
                api.open({
                    message: t`New Public Reservation`,
                });
                // sound.play();
            } finally {
            }
        });
        NotificationHub.connect();
        return () => {};
    }, []);
    return (
        <>
            {contextHolder}
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    className="d-none d-lg-block "
                    breakpoint="sm"
                    collapsed={collapsed}
                    width={190}
                    onCollapse={(value) => setCollapsed(value)}
                    style={{ background: colorBgContainer }}
                >
                    <div className="d-flex align-items-center justify-content-center logo-container border-bottom-main">
                        <div className="logo-wrap d-flex align-items-center">
                            {checkTheme.isDark ? (
                                <img src="/rezervirajse.png" width={180} alt="logo" />
                            ) : (
                                <img src="/rezervirajsedark.png" width={180} alt="logo" />
                            )}
                        </div>
                    </div>
                    <div className="text-center my-3">
                        <Button type="primary" onClick={() => navigate(`/reservations/create`)}>
                            <PlusCircleOutlined /> {t`New Reservation`}
                        </Button>
                    </div>
                    <Menu
                        mode="inline"
                        items={desktopMenuItems}
                        className="mt-5"
                        selectedKeys={selectedKeys}
                    />
                </Sider>
                <div className="d-lg-none mobile-menu" style={{ background: colorBgContainer }}>
                    <Menu mode="horizontal" selectedKeys={selectedKeys}>
                        <div className="d-flex align-items-center w-100 px-3 justify-space-between ">
                            <Menu.Item
                                key="/reservations"
                                className="mobile-menu-item cursor-pointer"
                                onClick={(x) => navigate('/reservations/create')}
                            >
                                <PlusCircleOutlined
                                    style={{ fontSize: '22px', paddingTop: '10px' }}
                                />
                            </Menu.Item>
                            <Menu.Item
                                id="/reservations/overview"
                                onClick={(_) => navigate('/reservations/overview')}
                                key="/reservations/overview"
                                className="mobile-menu-item"
                                itemID="/reservations/overview"
                                tabIndex={-1}
                            >
                                <ContactsOutlined
                                    style={{ fontSize: '22px', paddingTop: '10px' }}
                                />
                            </Menu.Item>

                            <Menu.Item
                                key="/waiting-list"
                                className="mobile-menu-item"
                                onClick={(_) => navigate('/waiting-list')}
                            >
                                <FieldTimeOutlined
                                    style={{ fontSize: '22px', paddingTop: '10px' }}
                                />
                            </Menu.Item>

                            <Button
                                type="primary"
                                shape="circle"
                                onClick={showDrawer}
                                icon={<AppstoreOutlined style={{ paddingTop: '3px' }} />}
                            ></Button>
                        </div>
                    </Menu>
                </div>
                <Drawer
                    placement="bottom"
                    onClose={onClose}
                    open={open}
                    extra={
                        <Row>
                            <Col span={24}>
                                <ClockComponent />
                            </Col>
                        </Row>
                    }
                >
                    <Menu mode="vertical" selectedKeys={selectedKeys}>
                        <Menu.Item
                            key="/availability"
                            className="mobile-menu-item"
                            onClick={(_) => {
                                navigate('/availability');
                                onClose();
                            }}
                        >
                            <CarryOutOutlined
                                style={{ fontSize: '22px', paddingTop: '10px' }}
                                className="me-1"
                            />
                            {t`/availability`}
                        </Menu.Item>
                        <Menu.Item
                            key="/services"
                            className="mobile-menu-item"
                            onClick={(_) => {
                                navigate('/services');
                                onClose();
                            }}
                        >
                            <PicRightOutlined
                                style={{ fontSize: '22px', paddingTop: '10px' }}
                                className="me-1"
                            />
                            {t`/services`}
                        </Menu.Item>
                        <Menu.Item
                            key="/users/public-users"
                            className="mobile-menu-item"
                            onClick={(_) => {
                                navigate('/users/public-users');
                                onClose();
                            }}
                        >
                            <UserSwitchOutlined
                                style={{ fontSize: '22px', paddingTop: '10px' }}
                                className="me-1"
                            />
                            {t`/users/public-users`}
                        </Menu.Item>
                        {role.includes('BackofficeAdministrator') && (
                            <Menu.Item
                                key="/users/backoffice-users"
                                className="mobile-menu-item"
                                onClick={(_) => {
                                    navigate('/users/backoffice-users');
                                    onClose();
                                }}
                            >
                                <UserSwitchOutlined
                                    style={{ fontSize: '22px', paddingTop: '10px' }}
                                    className="me-1"
                                />
                                {t`/users/backoffice-users`}
                            </Menu.Item>
                        )}
                    </Menu>
                    <Row className="align-items-center justify-space-between mt-3">
                        <Col span={8}>
                            <LanguagesDropDownComponent />
                        </Col>
                        <Col span={8}>
                            <LogoutButtonComponent />
                        </Col>
                        <Col span={8}>
                            <ThemeToggleComponent />
                        </Col>
                    </Row>
                </Drawer>

                <Layout>
                    <Header
                        style={{ background: colorBgContainer }}
                        className="border-bottom-main d-none d-lg-block px-0 mx-1"
                    >
                        <Row justify={'end'} className="me-2" gutter={[5, 0]}>
                            <Col style={{ marginRight: 'auto' }}>
                                <ClockComponent />
                            </Col>
                            <Col>
                                <ThemeToggleComponent />
                            </Col>
                            <Col>
                                <LanguagesDropDownComponent />
                            </Col>
                            <Col>
                                <LogoutButtonComponent />
                            </Col>
                        </Row>
                    </Header>
                    <Content className="px-lg-1">
                        <ApplicationRoutes />
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
                        <CreatedByComponent />
                    </Footer>
                </Layout>
            </Layout>
        </>
    );
};
