import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Checkbox, DatePicker, Form, Modal, Popconfirm, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShiftApi } from '../../../api/server.controller.context';
import type { PatchShiftRequestDto } from '../../../api/shift/req/patch-shift-req.dto';
import { GetUsersResponseDto } from '../../../api/user/res/get-user-res.dto';
import { CalendarUtility } from '../../../utils/CalendarUtility';
import { DateUtility } from '../../../utils/DateUtility';
import { CalendarEvent } from './types';

type Props = {
    onModalClose: (updatedShift?: PatchShiftRequestDto) => void | Promise<void>;
    user: GetUsersResponseDto;
    calendarEvent: CalendarEvent;
    onDeleteClickedAsync: (calendarEvent: CalendarEvent) => void | Promise<void>;
};

interface FormValues {
    from?: Dayjs;
    to?: Dayjs;
    isVacation: boolean;
}

export const UserUpdateShiftCalendarComponent = (props: Props) => {
    const { t } = useTranslation();
    const api = useShiftApi();

    const saveAsync = async () => {
        try {
            const values = await form.validateFields();
            const from = DateUtility.asAbsoluteUtcString(values.from!);
            const to = DateUtility.asAbsoluteUtcString(values.to!);
            const updatedShift = {
                from,
                to,
                id: props.calendarEvent!.shift.id,
                isVacation: values.isVacation,
            };
            await api.patch(updatedShift);
            message.success(t`Saved successfully`);
            props.onModalClose(updatedShift);
        } catch (error) {
            if (error && typeof error === 'object' && 'errorFields' in error) {
                return;
            }
            // message.error(t`possibleOverlap`);
        }
    };
    const initialValues: FormValues = useMemo(
        () => ({
            from: dayjs(props.calendarEvent?.start),
            to: dayjs(props.calendarEvent?.end),
            isVacation: Boolean(props.calendarEvent?.shift.isVacation),
        }),
        [props]
    );
    const [form] = Form.useForm<FormValues>();
    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues, form]);

    const beforeModalClose = () => {
        props.onModalClose();
    };
    return (
        <Modal
            width={'fit-content'}
            destroyOnClose
            open={Boolean(props.calendarEvent)}
            onCancel={props.onModalClose as any}
            afterClose={beforeModalClose}
            footer={[
                <Button key="cancel" onClick={beforeModalClose}>
                    {t`Cancel`}
                </Button>,
                <Button key="add" type="primary" onClick={saveAsync}>
                    {t`Save`}
                </Button>,
            ]}
        >
            <Avatar
                size="large"
                icon={<UserOutlined />}
                style={{ ...CalendarUtility.getEventStyle(props.user.colorString).style }}
            />
            <span>{props.user.userName}</span>

            <Form form={form} labelCol={{ span: 12 }}>
                <Form.Item
                    name="from"
                    label="From"
                    rules={[{ required: true, message: 'Please select a From date' }]}
                >
                    <DatePicker showTime showSecond={false} />
                </Form.Item>
                <Form.Item
                    name="to"
                    label="To"
                    rules={[{ required: true, message: 'Please select a To date' }]}
                >
                    <DatePicker showTime showSecond={false} />
                </Form.Item>
                <Form.Item label="Is Vacation" name="isVacation" valuePropName="checked">
                    <Checkbox />
                </Form.Item>
            </Form>
            <Popconfirm
                title={t`Sure to Delete?`}
                onConfirm={async (x) => {
                    x?.stopPropagation();

                    await props?.onDeleteClickedAsync?.(props.calendarEvent!);
                }}
            >
                <Button type="dashed" className="mt-1" block danger>
                    <DeleteOutlined /> {t`Delete`}
                </Button>
            </Popconfirm>
        </Modal>
    );
};
