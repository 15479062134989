import { CalendarOutlined, DeleteOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    Popconfirm,
    Row,
    Switch,
    Typography,
    message,
} from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { GetAvailabilitiesUserResponseDto } from '../../../../api/availability/res/get-availabilities-res.dto';
import { PostReservationReqDto } from '../../../../api/reservation/req/post-reservation-req.dto';
import { type GetReservationResDto } from '../../../../api/reservation/res/get-reservation-res.dto';
import { useApi } from '../../../../api/server.controller.context';
import { GetProvidedServiceResponseDto } from '../../../../api/services/res/get-provided-service-res.dto';
import { BarberColorTagComponent } from '../../../../components/barber/barber-color-tag.component';
import { DateUtility } from '../../../../utils/DateUtility';
import { IntlUtility } from '../../../../utils/IntUtility';
import { AvailabilityPage } from '../../../availability/availability.page';

type Props = { data?: GetReservationResDto; onEditFinished?: () => void | Promise<void> };
type ReservationCreateForm = Omit<PostReservationReqDto, 'from' | 'to'> & {
    from?: Dayjs;
    to?: Dayjs;
    barber?: Partial<GetAvailabilitiesUserResponseDto>;
    serviceIds?: string[];
    fromTime?: Dayjs | string;
    toTime?: Dayjs | string;
};
export const ReservationCreatePage = (props: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const api = useApi();

    const [servicesState, setServicesState] = useState<GetProvidedServiceResponseDto[]>();
    const load = useCallback(async () => {
        const services = await api.Services.get();
        setServicesState(services.data);
    }, [api]);
    useEffect(() => {
        load();
    }, [load]);

    const deleteAsync = async (id: string) => {
        try {
            await api.Reservation.delete(id);
            message.success(t`Deleted`);
        } catch (error) {
            message.error(t`Failed`);
        }
    };

    let initialValues: ReservationCreateForm;
    const [form] = useForm<ReservationCreateForm>();
    const editableState = props.data;

    if (editableState) {
        const from = DateUtility.asAbsoluteLocalDate(editableState.from);
        const to = DateUtility.asAbsoluteLocalDate(editableState.to);
        initialValues = {
            from: from.dayJsDate,
            isWalkIn: editableState.isWalkIn,
            barber: editableState.user,
            to: to.dayJsDate,
            userId: editableState.user.id,
            name: editableState.name,
            description: editableState.publicClient
                ? `${editableState.description ?? ''} - ${editableState.publicClient.phoneNumber}`
                : editableState.description,
            isNoShow: editableState.isNoShow,
            serviceIds: editableState.providedServices.map((x) => x.id),
            fromTime: from.dayJsDate.clone().format('HH:mm'),
            toTime: to.dayJsDate.clone().format('HH:mm'),
        };
    } else {
        initialValues = {
            from: undefined,
            barber: undefined,
            to: undefined,
            userId: '',
            name: '',
            description: '',
            isWalkIn: false,
            isNoShow: false,
            serviceIds: [],
            fromTime: undefined,
            toTime: undefined,
        };
    }

    const watchedBarber = useWatch(['barber'], form);

    const [isAvailabilityModalOpen, openAvailabilityModal] = useState(false);

    const onFormFinish = async (values: ReservationCreateForm) => {
        try {
            if (typeof values.fromTime === 'string') {
                const [h, m] = (values.fromTime as string).split(':');
                values.from = values.from?.set('hour', +h);
                values.from = values.from?.set('minute', +m);
            } else {
                values.from = values.from?.set('hours', values.fromTime!.hour());
                values.from = values.from?.set('minute', values.fromTime!.minute());
            }
            if (typeof values.toTime === 'string') {
                const [h, m] = (values.toTime as string).split(':');
                values.to = values.to?.set('hour', +h);
                values.to = values.to?.set('minute', +m);
            } else {
                values.to = values.to?.set('hours', values.toTime!.hour());
                values.to = values.to?.set('minute', values.toTime!.minute());
            }

            if (editableState) {
                const description = editableState.publicClient
                    ? values.description?.replace?.(editableState.publicClient.phoneNumber, '')
                    : values.description;
                await api.Reservation.patch({
                    id: editableState.id,
                    from: DateUtility.asAbsoluteUtcString(values.from!),
                    to: DateUtility.asAbsoluteUtcString(values.to!),
                    userId: values.barber!.id!,
                    isWalkIn: values.isWalkIn,
                    isNoShow: values.isNoShow,
                    serviceIds: values.serviceIds,
                    name: values.name,
                    description,
                });
                props?.onEditFinished?.();
            } else {
                await api.Reservation.post({
                    from: DateUtility.asAbsoluteUtcString(values.from!),
                    to: DateUtility.asAbsoluteUtcString(values.to!),
                    userId: values.barber!.id!,
                    isWalkIn: values.isWalkIn,
                    isNoShow: values.isNoShow,
                    serviceIds: values.serviceIds,
                    name: values.name,
                    description: values.description,
                });
            }

            navigate('/reservations/overview#calendarView');
        } catch (error) {}
    };

    return (
        <>
            <Row>
                <Col>
                    <Typography.Title level={2}>
                        {editableState ? t`Edit Reservation` : t`New Reservation`}-
                        {editableState?.publicClient && <b>{t`Public Reservation`}</b>}
                    </Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col style={{ width: '100%' }}>
                    <Card>
                        <Form<ReservationCreateForm>
                            form={form}
                            onFinish={onFormFinish}
                            labelAlign="left"
                            labelWrap
                            initialValues={initialValues}
                        >
                            <Form.Item
                                name="name"
                                rules={[
                                    { required: true, message: t`Please enter name` as string },
                                ]}
                            >
                                <Input placeholder={t`Client Name` as string} />
                            </Form.Item>
                            <Form.Item name="description">
                                <TextArea placeholder={t`Additional information` as string} />
                            </Form.Item>
                            <Row>
                                <Form.Item name="isWalkIn" label={t`Walk In`}>
                                    <Switch />
                                </Form.Item>
                                <Form.Item name="isNoShow" label={t`No Show`}>
                                    <Switch />
                                </Form.Item>
                            </Row>
                            <Form.Item
                                name="serviceIds"
                                rules={[
                                    {
                                        required: true,
                                        message: t`You must pick at least one services` as string,
                                    },
                                ]}
                                label={t`Pick Services`}
                            >
                                <Checkbox.Group style={{ width: '100%' }}>
                                    {servicesState?.map((x) => (
                                        <Checkbox value={x.id} style={{ lineHeight: '32px' }}>
                                            {x.name} -
                                            {IntlUtility.formatAsMinute(i18n.language, x.duration)}
                                        </Checkbox>
                                    ))}
                                </Checkbox.Group>
                            </Form.Item>

                            <Divider />
                            <Form.Item name="from" hidden></Form.Item>
                            <Form.Item name="to" hidden></Form.Item>
                            <Form.Item
                                name="barber"
                                rules={[
                                    {
                                        required: true,
                                        message: t`You must pick a time slot` as string,
                                    },
                                ]}
                            >
                                <Button
                                    style={{
                                        get borderColor() {
                                            const hasErrors = form.getFieldError('barber');
                                            if (hasErrors && hasErrors.length) {
                                                return '#ff4d4f';
                                            }
                                            return undefined;
                                        },
                                    }} // todo replace with theme error border..
                                    onClick={() => openAvailabilityModal(true)}
                                >{t`Pick Time Slot`}</Button>
                                {watchedBarber && watchedBarber.id && (
                                    <Row className="mt-1">
                                        <Col span={24} className="text-center">
                                            <CalendarOutlined />
                                            {IntlUtility.formatAsDate(
                                                i18n.language,
                                                form.getFieldsValue().from!.toDate()
                                            )}
                                        </Col>
                                        <Col xs={12}>
                                            <Form.Item
                                                name="fromTime"
                                                label={t`Start`}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            t`Start time is required` as string,
                                                    },
                                                ]}
                                                style={{ display: 'inline-block' }}
                                                labelCol={{ span: 24 }}
                                            >
                                                <Input type="time" />
                                            </Form.Item>
                                        </Col>

                                        <Col xs={12}>
                                            <Form.Item
                                                label={t`End`}
                                                name="toTime"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t`End time is required` as string,
                                                    },
                                                ]}
                                                labelCol={{ span: 24 }}
                                                style={{ display: 'inline-block' }}
                                            >
                                                <Input type="time" />
                                            </Form.Item>
                                        </Col>

                                        <BarberColorTagComponent barber={watchedBarber} />
                                    </Row>
                                )}
                            </Form.Item>

                            <Modal
                                open={isAvailabilityModalOpen}
                                destroyOnClose
                                footer={<></>}
                                width={'100%'}
                                onCancel={() => openAvailabilityModal(false)}
                            >
                                <AvailabilityPage
                                    onRow={(data) => {
                                        return {
                                            onClick() {
                                                const from = DateUtility.asAbsoluteLocalDate(
                                                    data.start
                                                ).dayJsDate;
                                                const maxTo = DateUtility.asAbsoluteLocalDate(
                                                    data.end
                                                ).dayJsDate;
                                                let to = from.add(40, 'minutes');
                                                if (to > maxTo) {
                                                    to = maxTo;
                                                }
                                                // if (memoizedDuration) {
                                                //     to = from.add(memoizedDuration, 'minute');
                                                // }
                                                form.setFieldsValue({
                                                    barber: data.barber,
                                                    from,
                                                    to,
                                                    toTime: to.clone().format('HH:mm'),
                                                    fromTime: from.clone().format('HH:mm'),
                                                });
                                                openAvailabilityModal(false);
                                            },
                                        };
                                    }}
                                    config={{
                                        hideServicesFilter: true,
                                        hideCreateReservationButton: true,
                                    }}
                                />
                            </Modal>
                            <Button type="primary" htmlType="submit">
                                {t`Submit`}
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
            {editableState && (
                <Popconfirm
                    title={t`Sure to Delete?`}
                    onConfirm={async (x) => {
                        x?.stopPropagation();
                        await deleteAsync(props.data!.id);
                        await props.onEditFinished?.();
                    }}
                >
                    <Button type="dashed" className="mt-1" block danger>
                        <DeleteOutlined /> {t`Delete`}
                    </Button>
                </Popconfirm>
            )}
        </>
    );
};
