import { Col, Row, Typography } from 'antd';
import { ElementType, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetAvailabilitiesResDto } from '../../api/availability/res/get-availabilities-res.dto';
import { GetReservationResDto } from '../../api/reservation/res/get-reservation-res.dto';
import { useAvailabilityApi } from '../../api/server.controller.context';
import { GetProvidedServiceResponseDto } from '../../api/services/res/get-provided-service-res.dto';
import { NotificationHub } from '../../signlar/notification.hub';
import { DateUtility } from '../../utils/DateUtility';
import { AvailabilityFilterContext } from './availability-filters.context';
import { AvailabilityBarberFilterComponent } from './components/availability-barber-filter.component';
import { AvailabilityDayFilterComponent } from './components/availability-day-filter.component';
import { AvailabilityPickDateDateRangeComponent } from './components/availability-pick-date-date-range.component';
import { AvailabilityServiceFilterComponent } from './components/availability-services-filter.component';
import { AvailabilityTableComponent } from './components/availability-table.component';
import { AvailabilityWeekFilterComponent } from './components/availability-week-filter.component';

type Props = {
    as?: ElementType;
    onRow?: (
        data: GetAvailabilitiesResDto,
        index?: number
    ) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>;
    config?: {
        hideServicesFilter?: boolean;
        hideCreateReservationButton?: boolean;
    };
};

const { Title } = Typography;
export const AvailabilityContextPage = (props: Props) => {
    let Tag: ElementType = Fragment;
    if (props.as) {
        Tag = props.as;
    }
    const { filterState, setFilterState } = useContext(AvailabilityFilterContext);
    const { t } = useTranslation();

    useEffect(() => {
        NotificationHub.on('NewPublicReservation', (_: GetReservationResDto) => {
            try {
                setFilterState((x) => ({ ...x }));
            } finally {
            }
        });
        return () => {};
    }, []);
    const availabilityApi = useAvailabilityApi();
    const [dataSource, setDataSource] = useState<GetAvailabilitiesResDto[] | undefined>();
    const load = useCallback(async () => {
        const response = await availabilityApi.get({
            from: DateUtility.asAbsoluteUtcString(filterState.dateRange.from),
            to: DateUtility.asAbsoluteUtcString(filterState.dateRange.to),
            barberId: filterState.selectedBarberId,
            serviceIds: filterState.serviceIds,
        });
        setDataSource(response.data);
    }, [availabilityApi, filterState]);

    useEffect(() => {
        load();
    }, [load]);

    return (
        <>
            <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Col span={24}>
                    <Title level={2}>{t`Availability`}</Title>
                </Col>
            </Row>
            <Row gutter={24}>
                {/* <Form component={false} form={form}>
                                <Form.Item
                                    name="serviceIds"
                                    className="m-0"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                t`You must pick a service to select that barber` as string,
                                        },
                                    ]}
                                > */}
                {!Boolean(props.config?.hideCreateReservationButton) && (
                    <Col xs={12} className="mt-1">
                        <AvailabilityServiceFilterComponent<GetProvidedServiceResponseDto>
                            style={{ width: '100%' }}
                            onChange={(serviceIds, value, services) => {}}
                        />
                    </Col>
                )}
                {/* </Form.Item>
                            </Form> */}

                <Col xs={12} className="mt-1">
                    <AvailabilityDayFilterComponent />
                </Col>

                <Col xs={12} className="mt-1">
                    <AvailabilityBarberFilterComponent />
                </Col>

                <Col xs={12} className="mt-1">
                    <AvailabilityWeekFilterComponent />
                </Col>

                <Col xs={24} className="mt-1 mb-1">
                    <AvailabilityPickDateDateRangeComponent />
                </Col>
            </Row>
            <AvailabilityTableComponent dataSource={dataSource} {...props} />
        </>
    );
};
