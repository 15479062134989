export interface PostBackofficeUserReqDto {
    username: string;
    password: string;
    confirmPassword: string;
    firstName: string;
    lastName: string;
    email?: string;
    accountType: AccountTypeEnum;
    colorString: string;
    serviceIds: string[];
}

export enum AccountTypeEnum {
    BackofficeAdministrator = 'BackofficeAdministrator',
    BackofficeEmployee = 'BackofficeEmployee',
    PublicSiteUser = 'PublicSiteUser',
}
