import { useEffect, useState } from 'react';
import { SubscribableState } from './SubscribableState';
export function useSubscribableState<T>(subscribableState: SubscribableState<T>): T {
    const [state, setState] = useState(subscribableState.get);
    useEffect(() => {
        const subscriptionId = subscribableState.subscribe(setState);
        return () => subscribableState.unsubscribe(subscriptionId);
    }, [subscribableState]);
    return state;
}
