import axios from 'axios';
import { ENV } from '../../env';
import { GetReservationReqDto } from './req/get-reservation-req.dto';
import { PatchReservationRequestDto } from './req/patch-reservation-request.dto';
import { PostReservationReqDto } from './req/post-reservation-req.dto';
import type { GetReservationResDto } from './res/get-reservation-res.dto';

export class ReservationApi {
    private readonly Url = `${ENV.API_URL}/api/reservation`;

    get(dto: GetReservationReqDto) {
        const params = new URLSearchParams();
        if (dto.dateRange.from) {
            params.append('from', dto.dateRange.from);
        }
        if (dto.dateRange.to) {
            params.append('to', dto.dateRange.to);
        }
        if (dto.publicUserId) {
            params.append('publicUserId', dto.publicUserId);
        }
        params.append('search', dto.search);
        params.append('reservationRequestType', dto.reservationType.toString());

        return axios.get<GetReservationResDto[]>(this.Url, { params });
    }

    post(dto: PostReservationReqDto) {
        return axios.post(this.Url, dto);
    }

    delete(id: string) {
        return axios.delete(this.Url, { params: new URLSearchParams({ id }) });
    }

    patch(data: PatchReservationRequestDto) {
        return axios.patch(this.Url, data);
    }
}
