import { Button, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWaitingListApi } from '../../api/server.controller.context';
import { PostWaitingListReqDto } from '../../api/waiting-list/req/post-waiting-list-req.dto';

type Props = {
    load: () => any;
};
export const WaitingListCreateComponent = (props: Props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = useForm<PostWaitingListReqDto>();

    const { t } = useTranslation();
    const api = useWaitingListApi();

    const handleAddService = async () => {
        try {
            const values = await form.validateFields();
            await api.post(values);
            handleModalClose();
            props.load();
        } catch (error) {}
    };

    const handleModalOpen = () => {
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };

    return (
        <>
            <Button type="primary" onClick={handleModalOpen}>
                {t`Create New`}
            </Button>

            <Modal
                destroyOnClose={true}
                afterClose={form.resetFields}
                open={modalVisible}
                onCancel={handleModalClose}
                footer={[
                    <Button key="cancel" onClick={handleModalClose}>
                        {t`Cancel`}
                    </Button>,
                    <Button key="add" type="primary" onClick={handleAddService}>
                        {t`Save`}
                    </Button>,
                ]}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label={t`Name` as string}
                        rules={[
                            {
                                required: true,
                                message: t`Name is required` as string,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={t`Description` as string}
                        rules={[
                            {
                                required: true,
                                message: t`Description is required` as string,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
