import { FileImageFilled } from '@ant-design/icons';
import {
    Avatar,
    Col,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Row,
    Select,
    Table,
    Typography,
    message,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useServicesApi } from '../../../api/server.controller.context';
import type { GetServiceGroupResponse } from '../../../api/service-group/res/get-provided-service-res.dto';
import type { GetProvidedServiceResponseDto } from '../../../api/services/res/get-provided-service-res.dto';
import { EmojiComponent } from '../../../components/emojis/emoji.component';
import { EditableCell } from '../../../components/table/cell/editable-cell.component';
import { ImageUtility } from '../../../utils/ImageUtility';
import { IntlUtility } from '../../../utils/IntUtility';
import { StringUtility } from '../../../utils/StringUtility';

type Props = {
    services: GetProvidedServiceResponseDto[] | undefined;
    serviceGroups: GetServiceGroupResponse[] | undefined;
    reload?: () => any;
};
export const ProvidedServiceTableComponent = (props: Props) => {
    const { t, i18n } = useTranslation();
    const [form] = useForm<GetProvidedServiceResponseDto>();
    const api = useServicesApi();

    const [file, setFile] = useState<File>();
    const [base64Image, setBase64Image] = useState<string>();

    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record: GetProvidedServiceResponseDto) => record.id === editingKey;
    const edit = (record: GetProvidedServiceResponseDto) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.id);
        setFile(undefined);
        setBase64Image(StringUtility.appendMimeType(record.imageBytes ?? '', 'jpeg'));
    };
    const cancel = () => {
        setEditingKey('');
        props?.reload?.();
    };
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) {
            message.error('File not uploaded, please try again..');
            return;
        }
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
            return;
        }
        const isLessThen5M = file.size / 1024 / 1024 < 5;
        if (!isLessThen5M) {
            message.error('Image must smaller than 5MB!');
            return;
        }
        const resizedFile = await ImageUtility.resizeImage(file, 261, 250, 1);
        setFile(resizedFile);
        StringUtility.toBase64String(resizedFile, (base64) => {
            setBase64Image(base64);
        });
    };

    const deleteAsync = async (record: GetProvidedServiceResponseDto) => {
        try {
            await api.delete(record.id);
            cancel();
        } catch (error) {}
    };

    const saveAsync = async (record: GetProvidedServiceResponseDto) => {
        try {
            const values = await form.validateFields();

            await api.patch({
                ...values,
                id: record.id,
                imageBytes: file,
                providedServiceGroup:
                    values.providedServiceGroup?.id ||
                    (values.providedServiceGroup as any as string),
            });
            cancel();
        } catch (error) {}
    };

    const columns = [
        {
            title: t`Name`,
            dataIndex: 'name',
            key: 'name',
            onCell: function (record: GetProvidedServiceResponseDto) {
                return {
                    record,
                    editing: isEditing(record),
                    dataIndex: this.dataIndex,
                    Component: Input,
                };
            },
        },
        {
            title: t`Translation`,
            dataIndex: 'translation',
            key: 'translation',
            onCell: function (record: GetProvidedServiceResponseDto) {
                return {
                    record,
                    editing: isEditing(record),
                    dataIndex: this.dataIndex,
                    Component: Input,
                };
            },
        },
        {
            title: t`Duration`,
            dataIndex: 'duration',
            key: 'duration',
            render(duration: number) {
                return IntlUtility.formatAsMinute(i18n.language, duration);
            },
            onCell: function (record: GetProvidedServiceResponseDto) {
                return {
                    record,
                    editing: isEditing(record),
                    dataIndex: this.dataIndex,
                    Component: <InputNumber addonAfter={t`Minutes`} min={1} max={2147483647} />,
                };
            },
        },

        {
            title: t`Group`,
            dataIndex: ['providedServiceGroup'],
            render: function (record: GetProvidedServiceResponseDto) {
                return t(record.name);
            },
            onCell: function (record: GetProvidedServiceResponseDto) {
                const options = props.serviceGroups?.map((x) => ({
                    key: x.id,
                    value: x.name,
                    label: t(x.name),
                    resource: x,
                }));
                return {
                    record,
                    editing: isEditing(record),
                    dataIndex: this.dataIndex,
                    Component: (
                        <Select
                            style={{ width: 120 }}
                            defaultValue={record.providedServiceGroup?.id}
                            onClick={(x) => x.stopPropagation()}
                            options={options}
                        />
                    ),
                    FormItemProps: {
                        valuePropName: 'defaultValue',
                        getValueFromEvent: (value: any, option: any) => option.props.resource.id,
                    },
                };
            },
        },

        {
            title: t`Image`,
            dataIndex: 'imageBytes',
            key: 'imageBytes',
            render(imageBytes: string) {
                if (!imageBytes) {
                    return <></>;
                }

                return (
                    <Row>
                        <Col xl={4} xxl={4}>
                            <img
                                src={StringUtility.appendMimeType(imageBytes, 'jpeg')}
                                alt="no_image"
                                width={100}
                                height={100}
                            />
                        </Col>
                    </Row>
                );
            },
            onCell: function (record: GetProvidedServiceResponseDto) {
                return {
                    record,
                    editing: isEditing(record),
                    dataIndex: this.dataIndex,
                    Component: (
                        <Row>
                            <Col>
                                <label htmlFor="file" className="cursor-pointer">
                                    {!base64Image ? (
                                        <Avatar
                                            shape="square"
                                            size={64}
                                            icon={<FileImageFilled />}
                                        ></Avatar>
                                    ) : (
                                        <Avatar shape="square" size={64} src={base64Image}></Avatar>
                                    )}
                                </label>
                                <input
                                    type="file"
                                    id="file"
                                    name="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    hidden
                                    className="d-none"
                                />
                            </Col>
                        </Row>
                    ),
                };
            },
        },
        {
            title: t`Emoji`,
            dataIndex: 'emoji',
            key: 'emoji',
            render: (_a: any, record: GetProvidedServiceResponseDto) => {
                return record.emoji;
            },
            onCell: function (record: GetProvidedServiceResponseDto) {
                return {
                    record,
                    editing: isEditing(record),
                    dataIndex: this.dataIndex,
                    Component: (
                        <EmojiComponent
                            value={record.emoji}
                            onChange={(x) => {
                                form.setFieldsValue({ emoji: x });
                            }}
                        />
                    ),
                    FormItemProps: {
                        getValueFromEvent: (value: any, option: any) => value,
                    },
                };
            },
        },

        {
            title: t`Operation`,
            dataIndex: 'operation',
            render: (_a: any, record: GetProvidedServiceResponseDto) => {
                const editable = isEditing(record);
                return editable ? (
                    <Row>
                        <Col xl={24} sm={24} md={24} xxl={24} xs={24}>
                            <Typography.Link onClick={(_) => saveAsync(record)}>
                                {t`Save`}
                            </Typography.Link>
                        </Col>
                        <Col xl={24} sm={24} md={24} xxl={24} xs={24}>
                            <Typography.Link onClick={cancel}>{t`Cancel`}</Typography.Link>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col xl={24} sm={24} md={24} xxl={24} xs={24}>
                            <Typography.Link
                                disabled={editingKey !== ''}
                                onClick={() => edit(record)}
                            >
                                {t`Edit`}
                            </Typography.Link>
                        </Col>
                        <Col xl={24} sm={24} md={24} xxl={24} xs={24}>
                            <Popconfirm
                                title={t`Sure to delete?`}
                                onConfirm={() => deleteAsync(record)}
                            >
                                <Typography.Link disabled={editingKey !== ''}>
                                    {t`Delete`}
                                </Typography.Link>
                            </Popconfirm>
                        </Col>
                    </Row>
                );
            },
        },
    ];

    return (
        <Form form={form} component={false}>
            <Table
                rowKey={(x) => x.id}
                tableLayout="fixed"
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                dataSource={props?.services ?? []}
                columns={columns as any}
                loading={!Boolean(props?.services)}
                scroll={{ x: 1000 }}
            />
        </Form>
    );
};
