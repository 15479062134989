import { Popconfirm, Space, Table, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAllApis } from '../../../api/server.controller.context';
import {
    GetUserEmailResDto,
    MessageStateEnum,
    MessageTypeEnum,
} from '../../../api/user/res/get-user-email-res.dto';

type Props = {
    userId: string;
};

export const UserEmailTableComponent = (props: Props) => {
    const { t } = useTranslation();
    const [data, setData] = useState<GetUserEmailResDto[] | undefined>(undefined);
    const api = useAllApis();
    const deleteAsync = async (record: GetUserEmailResDto) => {
        await api.Email.delete(record.id);
        await load();
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'JSON or String',
            dataIndex: 'jsonOrString',
            key: 'jsonOrString',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type: MessageTypeEnum) => MessageTypeEnum[type],
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: (state: MessageStateEnum) => MessageStateEnum[state],
        },
        {
            title: 'Fail Count',
            dataIndex: 'failCount',
            key: 'failCount',
        },
        {
            title: `Operation`,
            key: 'operation',
            dataIndex: 'operation',
            render: (_a: any, record: GetUserEmailResDto) => {
                return (
                    <Space>
                        <Popconfirm
                            title={t`Sure to delete?`}
                            onConfirm={(e) => {
                                e?.stopPropagation();
                                deleteAsync(record);
                            }}
                        >
                            <Typography.Link
                                onClick={(e) => {
                                    e?.stopPropagation();
                                }}
                            >
                                {t`Delete`}
                            </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const load = useCallback(async () => {
        const data = await api.Email.get(props.userId);
        setData(data.data);
    }, [props.userId, api]);
    useEffect(() => {
        load();
    }, [load]);
    return <Table dataSource={data} columns={columns} loading={!Boolean(data)} />;
};
