import { Row, Spin } from 'antd';

type Props = {};

export const CenteredSpinnerComponent = (props: Props) => {
    return (
        <Row justify="center" style={{ marginTop: '2rem' }}>
            <Spin />
        </Row>
    );
};
