import {
    Card,
    Col,
    ColorPicker,
    Popconfirm,
    Row,
    Table,
    Tabs,
    TabsProps,
    Tag,
    Typography,
} from 'antd';

import { ExpandableConfig } from 'antd/es/table/interface';
import { useTranslation } from 'react-i18next';
import { useBackofficeUserApi } from '../../../api/server.controller.context';
import { GetUsersResponseDto } from '../../../api/user/res/get-user-res.dto';
import { EditableCell } from '../../../components/table/cell/editable-cell.component';
import { StringUtility } from '../../../utils/StringUtility';
import { AvailabilityFiltersContext } from '../../availability/availability-filters.context';
import { AvailabilityDateTimeRangeFilterComponent } from '../../availability/components/availability-date-time-range-filter.component';
import { AvailabilityDayFilterComponent } from '../../availability/components/availability-day-filter.component';
import { AvailabilityTableFetchComponent } from '../../availability/components/availability-table-fetch.component';
import { AvailabilityWeekFilterComponent } from '../../availability/components/availability-week-filter.component';
import { UserServiceComponent } from './user-service.component';
import { UserShiftsCalendarComponent } from './user-shifts-calendar.component';

type Props = {
    dataSource: GetUsersResponseDto[] | undefined;
    removeColumns?: (keyof GetUsersResponseDto)[];
    reload: () => any;
    onEditClick: (entity: GetUsersResponseDto) => void;
};

export const BackofficeUserTableComponent = (props: Props) => {
    const api = useBackofficeUserApi();
    const { t } = useTranslation();

    const edit = (record: GetUsersResponseDto) => {
        props.onEditClick(record);
    };

    // const saveAsync = async (tableRecord: FormType) => {
    //     try {
    //         const values = await form.validateFields();
    //         let colorString: string = values.colorString;
    //         if (typeof colorString !== 'string' && 'toHexString' in colorString) {
    //             colorString = values.colorString.toHexString();
    //         }
    //         await api.patch({
    //             ...values,
    //             id: editableUser!.id,
    //             colorString,
    //         });
    //         props.reload();
    //     } catch (error) {}
    // };

    const deleteAsync = async (record: GetUsersResponseDto) => {
        await api.delete(record.id);
        props.reload();
    };

    let columns = [
        {
            title: t`User Name`,
            dataIndex: 'userName',
            key: 'userName',
            render: (userName: string, user: GetUsersResponseDto) => {
                return <Tag style={StringUtility.getColorStyles(user.colorString)}>{userName}</Tag>;
            },
        },
        {
            title: t`First Name`,
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: t`Last Name`,
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: t`Color`,
            dataIndex: 'colorString',
            key: 'colorString',
            render(x: string) {
                return <ColorPicker value={x} onChange={() => void 0} open={false} />;
            },
        },

        {
            title: t`Email`,
            dataIndex: 'email',
            key: 'email',
        },

        {
            title: t`Phone Number`,
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: t`Operation`,
            dataIndex: 'operation',
            render: (_a: any, record: GetUsersResponseDto) => {
                return (
                    <Row>
                        <Col xl={8} sm={24} md={24} xxl={8} xs={24}>
                            <Typography.Link
                                onClick={(e) => {
                                    e.stopPropagation();
                                    edit(record);
                                }}
                            >
                                {t`Edit`}
                            </Typography.Link>
                        </Col>
                        <Col xl={8} sm={24} md={24} xxl={8} xs={24}>
                            <Popconfirm
                                title={t`Sure to delete?`}
                                onConfirm={(e) => {
                                    e?.stopPropagation();
                                    deleteAsync(record);
                                }}
                            >
                                <Typography.Link
                                    onClick={(e) => {
                                        e?.stopPropagation();
                                    }}
                                >
                                    {t`Delete`}
                                </Typography.Link>
                            </Popconfirm>
                        </Col>
                    </Row>
                );
            },
        },
    ];

    const onChange = (key: string) => {};

    const asTabItem = (record: GetUsersResponseDto, index: number) => {
        const items: TabsProps['items'] = [
            {
                key: '1',
                label: t`Shifts`,
                children: (
                    <div
                        style={{
                            height: '100vh',
                        }}
                        key={index}
                    >
                        <UserShiftsCalendarComponent user={record} reload={props.reload} />
                    </div>
                ),
            },
            {
                key: '2',
                label: t`Services`,
                children: (
                    <div
                        style={{
                            height: '100vh',
                        }}
                        key={index}
                    >
                        <UserServiceComponent userId={record.id} />
                    </div>
                ),
            },
            {
                key: '3',
                label: t`Availabilities`,
                children: (
                    <AvailabilityFiltersContext initialValues={{ barberId: record.id }}>
                        <div
                            style={{
                                height: '100vh',
                            }}
                            key={index}
                        >
                            <Row>
                                <Col xxl={6}>
                                    <AvailabilityDayFilterComponent style={{ width: '100%' }} />
                                </Col>
                                <Col xxl={6}>
                                    <AvailabilityWeekFilterComponent style={{ width: '100%' }} />
                                </Col>
                                <Col xxl={6}>
                                    <AvailabilityDateTimeRangeFilterComponent
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>
                            <AvailabilityTableFetchComponent />
                        </div>
                    </AvailabilityFiltersContext>
                ),
            },
        ];
        return items;
    };

    const expandable: ExpandableConfig<GetUsersResponseDto> = {
        expandedRowRender: (record, index, _, expanded) => {
            return (
                <Card
                    style={{
                        border: `2px solid ${
                            StringUtility.getColorStyles(record.colorString).backgroundColor
                        }`,
                    }}
                >
                    <Tabs
                        defaultActiveKey="1"
                        onChange={onChange}
                        destroyInactiveTabPane
                        items={asTabItem(record, index)}
                    ></Tabs>
                </Card>
            );
        },
    };

    columns = columns.filter(
        (x) => props.removeColumns?.includes(x.key as keyof GetUsersResponseDto) !== true
    );

    return (
        <Table
            tableLayout="fixed"
            rowKey={(x) => x.id}
            components={{
                body: {
                    cell: EditableCell,
                },
            }}
            columns={columns as any}
            dataSource={props.dataSource ?? []}
            expandable={expandable}
            loading={!Boolean(props.dataSource)}
            scroll={{ x: 1000 }}
        />
    );
};
