import { Select } from 'antd';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateUtility } from '../../../utils/DateUtility';
import { AvailabilityFilterContext } from '../availability-filters.context';

type Props = { style?: React.CSSProperties; className?: string };
export const AvailabilityDayFilterComponent = (props: Props) => {
    const { setFilterState, filterState } = useContext(AvailabilityFilterContext); // Replace with your context and appropriate setter
    const { t } = useTranslation();
    const handleFilterChange = (value: any, option: any) => {
        setFilterState((x) => {
            return {
                ...x,
                selectedDayValue: value,
                selectedWeekValue: undefined,
                dateRange: {
                    from: option.resource.from,
                    to: option.resource.to,
                },
            };
        });
    };
    const { i18n } = useTranslation();
    const data = useMemo(
        () => DateUtility.toIntlDays(new Date(), 5, i18n.language),
        [i18n.language]
    );

    const dataForAntd = data.map((x) => {
        return {
            key: x.name,
            value: x.name,
            label: x.name,
            resource: x,
        };
    });

    return (
        <Select
            {...props}
            placeholder={t`Pick Day`}
            onChange={handleFilterChange}
            options={dataForAntd}
            value={filterState.selectedDayValue}
        ></Select>
    );
};
