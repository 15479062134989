import { PropsWithChildren, ReactNode, memo, useEffect } from 'react';
import { useAuthApi, useUserState } from '../api/server.controller.context';

interface Props {
    isAuthenticated: ReactNode;
    else: ReactNode;
}
export const AuthGuardComponent = memo((props: PropsWithChildren<Props>) => {
    const { isAuthenticated, hasRequestedLogout } = useUserState();
    const authApi = useAuthApi();
    useEffect(() => {
        if (hasRequestedLogout || window.location.pathname === '/') {
            return;
        }
        const verifyToken = async () => {
            await authApi.verifyTokenAsync();
        };
        verifyToken();
    }, [isAuthenticated, authApi, hasRequestedLogout]);

    return <>{isAuthenticated ? props.isAuthenticated : props.else}</>;
});
