import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useApi } from '../../../api/server.controller.context';
import { GetProvidedServiceResponseDto } from '../../../api/services/res/get-provided-service-res.dto';
import { AvailabilityFilterContext } from '../availability-filters.context';
import { AvailabilityServicesMultiselectComponent } from './availability-services-multiselect.component';
type SomeAntdOption = BaseOptionType | DefaultOptionType | (BaseOptionType | DefaultOptionType);
type Props<T> = {
    onChange?: (
        selectedValues: string[],
        option: SomeAntdOption[] | SomeAntdOption,
        object?: T[]
    ) => any;
    value?: string[];
} & { style?: React.CSSProperties; className?: string };

export const AvailabilityServiceFilterComponent = <T extends any>(props: Props<T>) => {
    const api = useApi();

    const [servicesState, setServicesState] = useState<GetProvidedServiceResponseDto[]>([]);
    const load = useCallback(async () => {
        const services = await api.Services.get();
        setServicesState(services.data);
    }, [api]);
    useEffect(() => {
        load();
    }, [load]);

    const handleFilterChange = (
        serviceIds: string[],
        option: SomeAntdOption[] | SomeAntdOption
    ) => {
        setFilterState((x) => {
            return { ...x, serviceIds };
        });
        const filteredServices = servicesState?.filter((x) => serviceIds.includes(x.id));
        props?.onChange?.(serviceIds, option, filteredServices as T[]);
    };
    const { setFilterState } = useContext(AvailabilityFilterContext);

    return (
        <AvailabilityServicesMultiselectComponent
            {...props}
            services={servicesState}
            onChange={handleFilterChange}
        />
    );
};
