import {
    HttpTransportType,
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    IHttpConnectionOptions,
    RetryContext,
} from '@microsoft/signalr';
import ApplicationConstants from '../application-constants';
import { LocalStorageUtils } from '../utils/LocalStorageUtility';

function nextRetryDelayInMilliseconds(context: RetryContext): number {
    const exponent = Math.min(context.previousRetryCount, 5);
    return Math.pow(2, exponent) * 1000;
}

export class SignalrHub {
    private connection?: HubConnection;

    constructor(private readonly hubUrl: string) {}

    public connect() {
        const userState = LocalStorageUtils.retrieve<UserState>(
            ApplicationConstants.LocalStorage.Keys.User
        );
        if (!userState) {
            throw new Error('No auth token found..');
        }
        const options: IHttpConnectionOptions = {
            headers: { Authorization: `${userState.token}` },
            accessTokenFactory: () => {
                return userState!.token.replace('Bearer ', '');
            },
            transport: HttpTransportType.ServerSentEvents,
        };
        this.connection = new HubConnectionBuilder()
            .withUrl(this.hubUrl, options)
            .withAutomaticReconnect({ nextRetryDelayInMilliseconds })
            .build();
        this.connection
            .start()
            .then(() => this.onConnected())
            .catch((error) => this.onConnectionError(error));
        this.connection.onreconnecting(this.onReconnecting);
        this.connection.onreconnected(this.onReconnected);
    }
    public disconnect() {
        this.connection?.stop();
    }
    public invoke(methodName: string, ...args: any[]) {
        if (this.connection?.state === HubConnectionState.Connected) {
            this.connection.invoke(methodName, ...args);
        } else {
            // TODO
        }
    }

    public on(methodName: string, callback: (...args: any[]) => void) {
        this.connection?.on(methodName, callback);
    }

    public off(methodName: string) {
        this.connection?.off(methodName);
        // this.connection.stop();
    }

    // TODO consider adding some more logging to these connection state handlers
    private onConnected() {
        this.onStateChanged();
    }

    private onConnectionError(error: any) {
        this.onStateChanged();
    }

    private onReconnecting(error: any) {
        this.onStateChanged();
    }

    private onReconnected(connectionId?: string) {
        this.onStateChanged();
    }

    private onStateChanged() {
        // const state = this.connection.state;
        // console.log('onStateChange', state);
        // this.state.update(state);
    }
}
