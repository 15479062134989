import { Select } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackofficeUserApi } from '../../../api/server.controller.context';
import { BarberColorTagComponent } from '../../../components/barber/barber-color-tag.component';
import { AvailabilityFilterContext } from '../availability-filters.context';

type Props = {};

export const AvailabilityBarberFilterComponent = (props: Props) => {
    const api = useBackofficeUserApi();
    const [dataSource, setDataSource] = useState<any[] | undefined>(undefined);
    const { setFilterState, filterState } = useContext(AvailabilityFilterContext); // Replace with your context and appropriate setter

    const { t } = useTranslation();

    const handleFilterChange = (value: any, option: any) => {
        setFilterState((x) => {
            return {
                ...x,
                selectedBarberId: value,
            };
        });
    };

    const load = useCallback(async () => {
        const response = await api.get();
        setDataSource(
            response.data.map((x) => ({
                key: x.id,
                value: x.id,
                label: <BarberColorTagComponent barber={x} />,
                resource: x,
            }))
        );
    }, [api]);

    useEffect(() => {
        load();
    }, [load]);
    return (
        <Select
            {...props}
            allowClear
            placeholder={t`Pick Barber`}
            onChange={handleFilterChange}
            options={dataSource}
            className="w-100"
            value={filterState.selectedBarberId}
        ></Select>
    );
};
