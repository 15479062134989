import axios from 'axios';
import ApplicationConstants from '../../application-constants';
import { ENV } from '../../env';
import { SubscribableState } from '../../observable/SubscribableState';
import { LocalStorageUtils } from '../../utils/LocalStorageUtility';
import { TokenUtility } from '../../utils/TokenUtility';
import { HandleAxiosErrorResDto } from '../error-res.dto';
import { AuthPostReqDto } from './req/auth-post-req.dto';
import { AuthPostResDto } from './res/auth-post-res.dto';

export class AuthApi {
    private readonly Url = `${ENV.API_URL}/api/Account`;

    readonly subscribable: SubscribableState<UserState>;
    constructor() {
        const state =
            LocalStorageUtils.retrieve<UserState>(ApplicationConstants.LocalStorage.Keys.User) ||
            this.getInitialState();
        this.subscribable = new SubscribableState(state);
        this.subscribable.subscribe(this.handleLocalStorage);
    }
    async login(dto: AuthPostReqDto) {
        try {
            const response = await axios.post<AuthPostResDto>(this.Url, dto);
            // if ('redirectUrl' in response.data) {
            //     let url = response.data.redirectUrl;
            //     window.location.href = url!;
            //     return;
            // }
            this.setAuth(response.data.token);
            return response;
        } catch (error) {
            return HandleAxiosErrorResDto(error);
        }
    }

    logout = () => {
        try {
            LocalStorageUtils.remove(ApplicationConstants.LocalStorage.Keys.User);
            this.setAuthToDefault(true);
        } catch (err) {
            // should show popup failed to log out but this SHOULD never happen...
            this.setAuthToDefault(true);
        }
    };

    async verifyTokenAsync() {
        try {
            const response = await axios.get(this.Url);
            if (response.status !== 200) {
                this.setAuthToDefault();
            }
        } catch (error) {
            this.setAuthToDefault();
            return HandleAxiosErrorResDto(error);
        }
    }

    private handleLocalStorage = (x: UserState) => {
        if (!x.isAuthenticated) {
            LocalStorageUtils.remove(ApplicationConstants.LocalStorage.Keys.User);
            LocalStorageUtils.remove(
                ApplicationConstants.LocalStorage.Keys.SponsoredRelatedAdministratorUserId
            );
        } else {
            LocalStorageUtils.store(ApplicationConstants.LocalStorage.Keys.User, x);
        }
    };
    private getInitialState = (): UserState => {
        return {
            name: '',
            email: '',
            role: '',
            token: '',
            isAuthenticated: false,
            hasRequestedLogout: false,
        };
    };

    private setAuth = async (token: string) => {
        const isAuthenticated = Boolean(token);
        const user = TokenUtility.Decode(token);
        this.subscribable.update({
            ...user,
            token,
            isAuthenticated,
        });
    };

    private setAuthToDefault = (hasRequestedLogout?: boolean) =>
        this.subscribable.update({
            ...this.getInitialState(),
            hasRequestedLogout: Boolean(hasRequestedLogout),
        });
}
