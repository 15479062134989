import { DateUtility } from './DateUtility';
import { IntlUtility } from './IntUtility';

export class StringUtility {
    static getColorStyles(colorString: string) {
        const style = {
            backgroundColor: colorString,
            color: StringUtility.getContrastYIQ(colorString),
        };
        return style;
    }
    static getContrastYIQ(hexColor: string) {
        hexColor = hexColor.replace('#', '');
        var r = parseInt(hexColor.substr(0, 2), 16);
        var g = parseInt(hexColor.substr(2, 2), 16);
        var b = parseInt(hexColor.substr(4, 2), 16);
        var yiq = (r * 299 + g * 587 + b * 114) / 1000;
        return yiq >= 128 ? 'black' : 'white';
    }

    static format(_this: string, t: (input: string) => string, ...args: any[]) {
        return _this.replace(/{(\d+)}/g, function (match, number) {
            const matched = typeof args[number] != 'undefined' ? args[number] : match;
            return t(matched[0]);
        });
    }
    static asAbsoluteLocalDateTimeString(language: string, dateString: string) {
        const date = DateUtility.asAbsoluteLocalDate(dateString).javascriptDate;
        return IntlUtility.formatAsDateTime(language, date);
    }

    static asAbsoluteLocalDateString(language: string, dateString: string) {
        const date = DateUtility.asAbsoluteLocalDate(dateString).javascriptDate;
        return IntlUtility.formatAsDate(language, date);
    }

    static asAbsoluteLocalTimeString(language: string, dateString: string) {
        const date = DateUtility.asAbsoluteLocalDate(dateString).javascriptDate;
        return IntlUtility.formatAsTime(language, date);
    }

    static toBase64String(file: File, callback: (base64String: string) => void) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(file);
    }

    static toBase64Promise(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                reader.addEventListener('load', () => resolve(reader.result as string));
                reader.readAsDataURL(file);
            } catch (error) {
                reject(error);
            }
        });
    }
    static appendMimeType(string: string, mimeType: string) {
        if (!string) {
            return undefined;
        }
        const imageSrc = `data:image/${mimeType};base64,${string}`;
        return imageSrc;
    }
}
