import { Form, Input } from 'antd';
import parsePhoneNumber from 'libphonenumber-js';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PostPublicUserReqDto } from '../../../api/user/req/post-public-user-req.dto';

interface UserCreationFormProps {
    onSubmit: (values: PostPublicUserReqDto) => void;
}
const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const CreatePublicUserFormComponent: React.FC<UserCreationFormProps> = ({ onSubmit }) => {
    const [form] = Form.useForm<PostPublicUserReqDto>();
    const { t } = useTranslation();

    const onFinish = (values: PostPublicUserReqDto) => {
        values.phoneNumber = parsePhoneNumber(values.phoneNumber)!.format('E.164');
        onSubmit(values);
    };

    const emailValidator = (_: any, value: string) => {
        if (emailRegex.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(t`Email is not valid`);
    };

    const phoneNumberValidator = (_: any, value: string) => {
        const ok = parsePhoneNumber(value);
        if (!ok) {
            return Promise.reject(t`Phone number must be in format` + ' +38592111222');
        }
        return Promise.resolve();
    };

    const confirmPasswordValidator = (_: any, value: string) => {
        const password = form.getFieldValue('password');
        if (password !== value) {
            return Promise.reject('Passwords do not match');
        }
        return Promise.resolve();
    };

    return (
        <Form form={form} onFinish={onFinish} layout="vertical" id="form" name="form">
            <Form.Item
                name="username"
                label={t`Username`}
                rules={[{ required: true, message: t`Please enter the username` as string }]}
            >
                <Input placeholder={t`Enter the username` as string} autoComplete="new-password" />
            </Form.Item>
            {/* <Form.Item
                name="password"
                label={t`Password`}
                rules={[
                    { required: true, message: t`Please enter the password` as string },
                    { min: 8, message: t`Password must be at least 8 characters long` as string },
                ]}
            >
                <Input.Password
                    placeholder={t`Enter the password` as string}
                    autoComplete="new-password"
                />
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                label={t`Confirm Password`}
                dependencies={['password']}
                rules={[
                    { required: true, message: t`Please confirm the password` as string },
                    { validator: confirmPasswordValidator },
                ]}
            >
                <Input.Password placeholder={t`Confirm the password` as string} />
            </Form.Item> */}
            <Form.Item
                name="firstName"
                label={t`First Name`}
                rules={[{ required: true, message: t`Please enter the first name` as string }]}
            >
                <Input placeholder={t`Enter the first name` as string} />
            </Form.Item>
            <Form.Item
                name="lastName"
                label={t`Last Name`}
                rules={[{ required: true, message: t`Please enter the last name` as string }]}
            >
                <Input placeholder={t`Enter the last name` as string} />
            </Form.Item>
            <Form.Item
                name="email"
                label={t`Email`}
                rules={[
                    {
                        message: t`Email is required` as string,
                        required: true,
                    },
                    {
                        validator: emailValidator,
                    },
                ]}
            >
                <Input placeholder={t`Enter the email` as string} />
            </Form.Item>
            <Form.Item
                name="phoneNumber"
                label={t`Phone Number`}
                rules={[
                    {
                        message: t`Phone Number is required` as string,
                        required: true,
                    },
                    { validator: phoneNumberValidator },
                ]}
            >
                <Input placeholder={t`Phone Number` as string} />
            </Form.Item>
        </Form>
    );
};
