import axios from 'axios';
import { ENV } from '../../env';
import { PostUserServiceReqDto } from './req/post-user-service-req.dto';
import { GetUserServiceResDto } from './res/get-user-service-res.dto';

export class UserServiceApi {
    private readonly Url = `${ENV.API_URL}/api/userService`;

    getAllForUserId(userId: string) {
        return axios.get<GetUserServiceResDto[]>(this.Url + `/${userId}`);
    }

    post(dto: PostUserServiceReqDto) {
        return axios.post(this.Url, dto);
    }

    delete(id: string) {
        const params = new URLSearchParams();
        params.append('id', id);
        return axios.delete(this.Url, { params });
    }
}
