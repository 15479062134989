export class ArrayUtility {
    static UpdateElementByReferenceOrIdentifier<T>(
        array: T[],
        item: T,
        identifierFn: (x: T) => boolean
    ) {
        const findElementByIndex = array.indexOf(item);
        if (findElementByIndex === -1) {
            // try to find by identifier

            const foundElement = array.find(identifierFn);
            if (!foundElement) {
                throw new Error(
                    'Unable to find the element please make sure that the function is correct'
                );
            }
            const indexOfIdentifier = array.indexOf(foundElement);
            array[indexOfIdentifier] = item;
            return;
        }
        array[findElementByIndex] = item;
    }
}
