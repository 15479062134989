import axios from 'axios';
import { ENV } from '../../env';
import { PatchUserRequestDto } from './req/patch-user-req.dto';
import { PostBackofficeUserReqDto } from './req/post-backoffice-user-req.dto';
import { GetUsersResponseDto } from './res/get-user-res.dto';

export class BackofficeUserApi {
    private readonly Url = `${ENV.API_URL}/api/backofficeUser`;

    get() {
        return axios.get<GetUsersResponseDto[]>(this.Url);
    }

    getPublicUsers() {
        const params = new URLSearchParams({ includePublicUsers: 'true' });
        return axios.get<GetUsersResponseDto[]>(this.Url, { params });
    }

    post(dto: PostBackofficeUserReqDto) {
        return axios.post(this.Url, dto);
    }
    patch(dto: PatchUserRequestDto) {
        return axios.patch(this.Url, dto);
    }
    delete(id: string) {
        const params = new URLSearchParams();
        params.append('id', id);
        return axios.delete(this.Url, { params });
    }
}
