import { Select } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetProvidedServiceResponseDto } from '../../../api/services/res/get-provided-service-res.dto';
import { IntlUtility } from '../../../utils/IntUtility';
type SomeAntdOption =
    | BaseOptionType
    | DefaultOptionType
    | ((BaseOptionType | DefaultOptionType) & { key?: string });
type ObtainKeys<T, V> = keyof {
    [K in keyof T as T[K] extends V ? K : never]: any;
};
type Props<T> = {
    onChange?: (
        selectedValues: string[],
        option: SomeAntdOption[] | SomeAntdOption,
        object?: T[]
    ) => any;
    value?: string[];
    services?: GetProvidedServiceResponseDto[];
    keySelector?: ObtainKeys<GetProvidedServiceResponseDto, string>;
} & { style?: React.CSSProperties; className?: string };

export const AvailabilityServicesMultiselectComponent = <T extends any>(props: Props<T>) => {
    const { i18n } = useTranslation();
    const handleFilterChange = (value: string[], option: SomeAntdOption[] | SomeAntdOption) => {
        const keys = Array.isArray(option) ? option.map((x) => x.key) : [option.key];

        const filteredServices = props.services?.filter((x) => keys.includes(x.id)) ?? [];

        props?.onChange?.(
            filteredServices.map((x) => (props.keySelector ? x[props.keySelector] : x.id)),
            option,
            filteredServices as T[]
        );
    };
    const { t } = useTranslation();

    const options = useMemo(() => {
        return (
            props.services?.map((x) => {
                return {
                    label: (
                        <>
                            {`${x.name} ${IntlUtility.formatAsMinute(i18n.language, x.duration)} ${
                                x.emoji ?? ''
                            }`}
                        </>
                    ),
                    key: x.id,
                    value: x.name,
                    entireObject: x,
                };
            }) ?? []
        );
    }, [i18n.language, props.services]);

    return (
        <Select
            {...props}
            onChange={handleFilterChange}
            mode="multiple"
            placeholder={t`Pick Services`}
            className="w-100"
            value={props.value}
            onSearch={(filterBy) => {
                const filteredOptions = options.filter((x) =>
                    x.entireObject.name.includes(filterBy)
                );
                const filteredValues = filteredOptions.map((x) => x.entireObject.id);
                handleFilterChange(filteredValues, filteredOptions);
            }}
            options={options}
        >
            {/* {props.services?.map((option) => (
                <Option key={option.id} value={option.id} label={option.name}>
                    <>
                        {option.name}
                        {IntlUtility.formatAsMinute(i18n.language, option.duration)}
                        {option.emoji}
                    </>
                </Option>
            ))} */}
        </Select>
    );
};
