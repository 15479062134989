export default class ApplicationConstants {
    static Defaults = {
        Guid: '00000000-0000-0000-0000-000000000000',
    };
    static Languages = {
        HrHr: 'hr-HR',
        EnUs: 'en-US',
    };
    static LocalStorage = {
        Keys: {
            StoragePrefix: '__REZERVIRAJSE_OFFICE__',
            get User() {
                return `__${this.StoragePrefix}__:user`;
            },
            get RefreshToken() {
                return `__${this.StoragePrefix}__:refresh`;
            },
            CalendarView: 'CalendarView',
            CssStyles: 'CssStyles',
            SelectedLang: 'SelectedLang',
            ReservationDraft: 'ReservationDraft',
            SponsoredRelatedAdministratorUserId: 'SponsoredRelatedAdministratorUserId',
            ThemeChanged: 'ThemeChanged',
        },
    };
    static Roles = {
        get Weights() {
            return {
                [this.BackofficeAdministrator]: 99,
                [this.PublicSiteUser]: 1,
                [this.BackofficeEmployee]: 2,
                [this.SuperAdmin]: 1024,
            };
        },
        AllRoles: '*',
        BackofficeAdministrator: 'BackofficeAdministrator',
        BackofficeEmployee: 'BackofficeEmployee',
        PublicSiteUser: 'PublicSiteUser',
        SuperAdmin: 'SuperAdmin',
        get CreatableRoles() {
            return {
                BackofficeAdministrator: this.BackofficeAdministrator,
                PublicSiteUser: this.PublicSiteUser,
                BackofficeEmployee: this.BackofficeEmployee,
            };
        },
    };
    static DefaultDateTimeFormats: {
        Time: Intl.DateTimeFormatOptions;
        Date: Intl.DateTimeFormatOptions;
        DateTime: Intl.DateTimeFormatOptions;
        Week: Intl.DateTimeFormatOptions;
        Second: Intl.DateTimeFormatOptions;
    } = {
        Time: {
            hour: '2-digit',
            minute: '2-digit',
        },
        Date: {
            weekday: 'long',
            day: '2-digit',
            month: '2-digit',
        },
        DateTime: {
            weekday: 'long',
            day: '2-digit',
            month: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        },
        Week: {
            month: '2-digit',
            day: '2-digit',
        },
        get Second() {
            return {
                ...this.Time,
                second: '2-digit',
            } as Intl.DateTimeFormatOptions;
        },
    };

    static Colors = {
        PrimaryColor: '--primary-color',
        DangerColor: '--danger-color',
    };
}
