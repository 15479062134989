import { notification } from 'antd';
import axios from 'axios';
import ApplicationConstants from '../application-constants';
import { GetAndInitI18N } from '../i18n/i18n.config';
import { LocalStorageUtils } from '../utils/LocalStorageUtility';
import { StringUtility } from '../utils/StringUtility';
import { ServerControllerInstance } from './server.controller';
export const SetupAxiosErrorInterceptor = function SetupAxiosErrorInterceptor() {
    axios.interceptors.response.use(
        function (response) {
            return response;
        },

        function (exception) {
            const errResponse = exception.response;
            if (errResponse != null && errResponse.data != null) {
                const resConfig = errResponse.config;
                const i18n = GetAndInitI18N();
                let translatedMessage = '';
                translatedMessage = i18n.t(errResponse.status);

                if (errResponse.status === 401) {
                    ServerControllerInstance.Auth.logout();
                }
                let translatedDescription = '';
                if (typeof errResponse.data === 'string') {
                    if (errResponse.data) {
                        translatedMessage = i18n.t(errResponse.data);
                    }
                } else {
                    if ('customErrorCode' in errResponse.data) {
                        if ('customStatusCode' in errResponse.data) {
                            translatedMessage = i18n.t(errResponse.data.customStatusCode);
                        } else {
                            translatedMessage = i18n.t(errResponse.status);
                        }
                        translatedDescription = StringUtility.format(
                            i18n.t(errResponse.data.customErrorCode) ?? '',
                            i18n.t,
                            errResponse.data.errors
                        );
                    } else {
                        translatedMessage = i18n.t(errResponse.status);
                        translatedDescription = i18n.t('GenericValidationError');
                    }
                }

                notification.error({
                    message: translatedMessage,
                    description: translatedDescription,
                    duration: 5,
                });
            } else if (exception.request != null) {
                notification.error({
                    message: 'Network Error',
                    description:
                        'Connection to the API could not be established.\nIt may be temporarily unavailable or you may have issues with your internet connection.',
                    duration: 5,
                });
            } else if (exception.message != null) {
                notification.error({
                    message: 'Network Error',
                    description: exception.message,
                    duration: 5,
                });
            }

            return Promise.reject(exception);
        }
    );
};

export const SetupAxiosAuthorizationInterceptor = function SetupAxiosAuthorizationInterceptor() {
    axios.interceptors.request.use((config: any) => {
        const user = LocalStorageUtils.retrieve<UserState>(
            ApplicationConstants.LocalStorage.Keys.User
        );
        if (user?.token) {
            config.headers.Authorization = `${user.token}`;
        }
        return config;
    });
};

export const SetupAxiosRateLimitingHeader = function SetupAxiosRateLimitingHeader() {
    axios.interceptors.request.use((config) => {
        config.headers.set('X-ClientId', 'mankaze-admin');
        return config;
    });
};
