import { ReservationFilterContextProvider } from '../../components/filters/reservation-filters.context';
import { ReservationContextPage } from './reservation-context.page';

type Props = {
    filterBySpecificPublicUserId?: string;
};

export const ReservationPage = (props: Props) => {
    return (
        <ReservationFilterContextProvider>
            <ReservationContextPage publicUserId={props.filterBySpecificPublicUserId} />
        </ReservationFilterContextProvider>
    );
};
