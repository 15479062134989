import { Select } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReservationFilterContext } from './reservation-filters.context';
export enum GetReservationRequestType {
    ALL,
    PublicOnly,
    BackofficeOnly,
}
type Props = { style?: React.CSSProperties; className?: string };

export const ReservationRequestTypeFilterComponent = (props: Props) => {
    const { setFilterState, filterState } = useContext(ReservationFilterContext); // Replace with your context and appropriate setter
    const { t } = useTranslation();
    const handleFilterChange = (value: GetReservationRequestType) => {
        setFilterState((x) => {
            return {
                ...x,
                reservationType: value,
            };
        });
    };

    return (
        <Select
            defaultValue={GetReservationRequestType.ALL}
            onChange={handleFilterChange}
            value={filterState.reservationType}
            {...props}
            options={[
                {
                    key: GetReservationRequestType.ALL,
                    value: GetReservationRequestType.ALL,
                    label: t`All`,
                },
                {
                    key: GetReservationRequestType.PublicOnly,
                    value: GetReservationRequestType.PublicOnly,
                    label: t`Loyalty Only`,
                },
                {
                    key: GetReservationRequestType.BackofficeOnly,
                    value: GetReservationRequestType.BackofficeOnly,
                    label: t`Admin Created`,
                },
            ]}
        ></Select>
    );
};
