import { Form } from 'antd';
import { FC, isValidElement } from 'react';
import { EditableCellPropsWithHtmlProps } from './types';

export const EditableCell: FC<EditableCellPropsWithHtmlProps> = ({
    children,
    dataIndex,
    editing,
    Component,
    FormItemProps,
    record,
    ...otherProps
}) => {
    const ComponentAsAny = Component as any;

    return (
        <td {...otherProps}>
            {editing ? (
                <Form.Item name={dataIndex} {...FormItemProps}>
                    {isValidElement(ComponentAsAny) ? { ...ComponentAsAny } : <ComponentAsAny />}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

export const TableBody: FC<EditableCellPropsWithHtmlProps> = ({ children, ...otherProps }) => {
    otherProps = {
        ...otherProps,
        style: {
            border: '1px solid red',
        },
    };
    return <tbody {...otherProps}>{children}</tbody>;
};
