import { Select } from 'antd';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateUtility } from '../../../../utils/DateUtility';
import { ReservationFilterContext } from './reservation-filters.context';
type Props = { style?: React.CSSProperties; className?: string };

export const ReservationWeekFilterComponent = (props: Props) => {
    const { setFilterState, filterState } = useContext(ReservationFilterContext); // Replace with your context and appropriate setter
    const { t } = useTranslation();

    const handleFilterChange = (value: any, option: any) => {
        setFilterState((x) => {
            return {
                ...x,
                selectedDayValue: undefined,
                selectedWeekValue: value,
                dateRange: {
                    from: option.resource.from,
                    to: option.resource.to,
                },
            };
        });
    };
    const { i18n } = useTranslation();
    const data = useMemo(
        () => DateUtility.toIntlWeeks(new Date(), 5, i18n.language),
        [i18n.language]
    );

    const dataForAntd = data.map((x) => {
        return {
            key: x.name,
            value: x.name,
            label: x.name,
            resource: x,
        };
    });

    return (
        <Select
            {...props}
            onChange={handleFilterChange}
            placeholder={t`Pick Week`}
            value={filterState.selectedWeekValue}
            options={dataForAntd}
        ></Select>
    );
};
