import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntlUtility } from '../utils/IntUtility';

export const ClockComponent = () => {
    const [date, setDate] = useState(new Date());
    const { i18n } = useTranslation();
    useEffect(() => {
        const timerId = setInterval(() => setDate(new Date()), 1000);
        return () => clearInterval(timerId);
    });
    return <div className="clock">{IntlUtility.formatAsClock(i18n.language, date.getTime())}</div>;
};
