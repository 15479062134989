import { ThemeConfig } from 'antd';
import { StringUtility } from './StringUtility';
export class CalendarUtility {
    static getEventStyle(colorString: string) {
        const style = {
            backgroundColor: colorString,
            color: StringUtility.getContrastYIQ(colorString),
        };

        return {
            style,
        };
    }

    static getBackgroundStyle = () => {
        const style = {
            backgroundColor: 'white',
        };
        return {
            style,
        };
    };

    static getBackgroundsForCells = (theme: ThemeConfig) => {
        const style = {
            backgroundColor: theme.token?.colorBgLayout,
        };
        return {
            style,
        };
    };

    static getLocalizedMessages = (localizations: any) => {
        const messages = {
            date: localizations.Date,
            time: localizations.Time,
            event: localizations.Event,
            allDay: localizations.AllDay,
            week: localizations.Week,
            work_week: localizations.WorkWeek,
            day: localizations.Day,
            month: localizations.Month,
            previous: localizations.Previous,
            next: localizations.Next,
            yesterday: localizations.Yesterday,
            tomorrow: localizations.Tomorrow,
            today: localizations.Today,
            agenda: localizations.Agenda,
            noEventsInRange: localizations.NoEventsInRage,
            showMore: (count: number) => `+${count} ${localizations.More}`,
        };
        return messages;
    };
}
