import { ThemeConfig } from 'antd';
import ApplicationConstants from './application-constants';
import { SubscribableState } from './observable/SubscribableState';
import { LocalStorageUtils } from './utils/LocalStorageUtility';

export class ThemeSubscribable {
    readonly subscribable: SubscribableState<ThemeConfig & { isDark?: boolean }>;
    constructor() {
        const state =
            LocalStorageUtils.retrieve<ThemeConfig>(
                ApplicationConstants.LocalStorage.Keys.ThemeChanged
            ) || this.getInitialState();
        this.subscribable = new SubscribableState(state);
        this.subscribable.subscribe(this.handleLocalStorage);
    }

    private handleLocalStorage = (state: ThemeConfig) => {
        if (!state.token) {
            LocalStorageUtils.remove(ApplicationConstants.LocalStorage.Keys.ThemeChanged);
        } else {
            LocalStorageUtils.store(ApplicationConstants.LocalStorage.Keys.ThemeChanged, state);
        }
    };
    private getInitialState = (): ThemeConfig => {
        return {};
    };

    setTheme = (config: ThemeConfig) => {
        this.subscribable.update({
            ...config,
        });
    };

    setToDefault = () =>
        this.subscribable.update({
            ...this.getInitialState(),
        });
}
