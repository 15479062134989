import { AuthApi } from './auth/auth.api';
import { AvailabilityApi } from './availability/availability.api';
import { ReservationApi } from './reservation/reservation.api';
import { ServiceGroupApi } from './service-group/service-group.api';
import { ServicesApi } from './services/services.api';
import { ShiftApi } from './shift/shift.api';
import { UserServiceApi } from './user-service/user-services.api';
import { BackofficeUserApi } from './user/backoffice-user.api';
import { PublicUserApi } from './user/public-user.api';
import { UserEmailApi } from './user/user-email.api';
import { WaitingListApi } from './waiting-list/waiting-list.api';

export type ServerControllerType = ServerController;
class ServerController {
    Auth = new AuthApi();
    Reservation = new ReservationApi();
    Services = new ServicesApi();
    WaitingList = new WaitingListApi();
    BackofficeUser = new BackofficeUserApi();
    PublicUser = new PublicUserApi();
    UserService = new UserServiceApi();
    Shift = new ShiftApi();
    Availability = new AvailabilityApi();
    Email = new UserEmailApi();
    ServiceGroup = new ServiceGroupApi();
}

export const ServerControllerInstance = new ServerController();
