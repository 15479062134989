import { RedoOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, List, Popconfirm, Row, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../api/server.controller.context';
import { PatchWaitingListReqDto } from '../../api/waiting-list/req/patch-waiting-list-req.dto';
import { GetWaitingListResDto } from '../../api/waiting-list/res/get-waiting-list-res.dto';
import { WaitingListCreateComponent } from './waiting-list-create.component';
import { WaitingListEditComponent } from './waiting-list-edit.component';

type Props = {};

const { Title, Text } = Typography;
export const WaitingListComponent = (props: Props) => {
    const api = useApi();
    const { t } = useTranslation();

    const [dataSource, setDataSource] = useState<GetWaitingListResDto[] | undefined>(undefined);

    const load = useCallback(async () => {
        const response = await api.WaitingList.get();
        setDataSource(response.data);
        setEditItem(undefined);
    }, [api]);

    const deleteAsync = async (record: GetWaitingListResDto) => {
        await api.WaitingList.delete(record.id);
        await load();
    };

    useEffect(() => {
        load();
    }, [load]);

    const [editItem, setEditItem] = useState<PatchWaitingListReqDto>();

    return (
        <>
            <Row
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                className="my-3 me-2"
            >
                <Col>
                    <Title level={2}>{t`Waiting list`}</Title>
                </Col>
                <Col>
                    <WaitingListCreateComponent load={load} />
                </Col>
            </Row>
            {editItem && <WaitingListEditComponent load={load} data={editItem} />}
            <Card>
                <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    dataSource={dataSource}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Row>
                                    <Col xl={24} sm={24} md={24} xxl={24} xs={24}>
                                        <Typography.Link
                                            disabled={Boolean(editItem)}
                                            onClick={() => setEditItem(item)}
                                        >
                                            {t`Edit`}
                                        </Typography.Link>
                                    </Col>
                                    <Col xl={24} sm={24} md={24} xxl={24} xs={24}>
                                        <Popconfirm
                                            title={t`Sure to delete?`}
                                            onConfirm={(_) => deleteAsync(item)}
                                        >
                                            <Typography.Link disabled={Boolean(editItem)}>
                                                {t`Delete`}
                                            </Typography.Link>
                                        </Popconfirm>
                                    </Col>
                                </Row>,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={
                                    <Avatar
                                        icon={<RedoOutlined spin />}
                                        style={{ backgroundColor: '#87d068' }}
                                    />
                                }
                                title={
                                    <Title level={5} className="m-0">
                                        {item.name}
                                    </Title>
                                }
                                description={<Text>{item.description}</Text>}
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </>
    );
};
