import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { AvailabilityFilterContext } from '../availability-filters.context';
type Props = { style?: React.CSSProperties; className?: string };
export const AvailabilityDateTimeRangeFilterComponent = (props: Props) => {
    const { setFilterState, filterState } = useContext(AvailabilityFilterContext);

    const handleDateChange = (dates: any) => {
        if (!dates) {
            return;
        }
        const [from, to] = dates;
        setFilterState((x) => {
            return {
                ...x,
                selectedDayValue: undefined,
                selectedWeekValue: undefined,
                dateRange: {
                    from,
                    to,
                },
            };
        });
    };

    return (
        <DatePicker.RangePicker
            {...props}
            onChange={handleDateChange}
            value={[dayjs(filterState.dateRange.from), dayjs(filterState.dateRange.to)]}
        />
    );
};
