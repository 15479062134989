import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import {
    SetupAxiosAuthorizationInterceptor,
    SetupAxiosErrorInterceptor,
    SetupAxiosRateLimitingHeader,
} from './api/axios.interceptor';
import { ServerControllerInstance } from './api/server.controller';
import { ServerContextProvider } from './api/server.controller.context';
import { AppConfigContextProvider } from './app-config.context';
import { AppContextConfigInstance } from './app.config';
import ApplicationConstants from './application-constants';
import { GetAndInitI18N } from './i18n/i18n.config';
import './index.css';

dayjs.extend(utc);

window.onstorage = async (e) => {
    if (!e.isTrusted) {
        return;
    }
    // if (e.key === ServicesApi.name) {
    //     await ServerControllerInstance.Services.refreshData();
    if (e.key === ApplicationConstants.LocalStorage.Keys.ThemeChanged) {
        // }
    }
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

SetupAxiosErrorInterceptor();
GetAndInitI18N();
SetupAxiosAuthorizationInterceptor();
SetupAxiosRateLimitingHeader();
root.render(
    <React.StrictMode>
        <ServerContextProvider serverController={ServerControllerInstance}>
            <AppConfigContextProvider appConfig={AppContextConfigInstance}>
                <App />
            </AppConfigContextProvider>
        </ServerContextProvider>
    </React.StrictMode>
);

const registerServiceWorker = async () => {
    if ('serviceWorker' in navigator) {
        try {
            const registration = await navigator.serviceWorker.register('/service-worker.js', {
                scope: '/',
            });
            if (registration.installing) {
                console.log('Service worker installing');
            } else if (registration.waiting) {
                console.log('Service worker installed');
            } else if (registration.active) {
                console.log('Service worker active');
            }
        } catch (error) {
            console.error(`Registration failed with ${error}`);
        }
    }
};

// …

// registerServiceWorker();
