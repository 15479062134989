import axios from 'axios';
import { ENV } from '../../env';
import type { GetServiceGroupResponse } from './res/get-provided-service-res.dto';

export class ServiceGroupApi {
    private readonly Url = `${ENV.API_URL}/api/ProvidedServicesGroup`;

    get() {
        return axios.get<GetServiceGroupResponse[]>(this.Url);
    }
}
