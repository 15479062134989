import { List, Row, Skeleton, Switch, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useServicesApi, useUserServiceApi } from '../../../api/server.controller.context';
import { GetProvidedServiceResponseDto } from '../../../api/services/res/get-provided-service-res.dto';
import { IntlUtility } from '../../../utils/IntUtility';

type Props = { userId: string };
type CustomType = GetProvidedServiceResponseDto & {
    isChecked: boolean;
    userServiceId?: string;
};

export const UserServiceComponent = (props: Props) => {
    const servicesApi = useServicesApi();
    const userServiceApi = useUserServiceApi();
    const { t, i18n } = useTranslation();

    const [allServices, setAllServices] = useState<CustomType[]>();

    const [switchLoader, setSwitchLoader] = useState<{
        [key: string]: boolean;
    }>({});

    const loadUserServices = useCallback(async () => {
        const userServicesResponse = await userServiceApi.getAllForUserId(props.userId);
        const services = await servicesApi.get();

        const mapped: CustomType[] = services.data.map((x) => {
            const element = userServicesResponse.data.find((y) => y.providedServiceId === x.id);
            return {
                ...x,
                isChecked: Boolean(element),
                userServiceId: element?.id,
            };
        });
        setAllServices(mapped);
    }, [userServiceApi, props.userId, servicesApi]);

    const onChangeAsync = async (checked: boolean, item: CustomType) => {
        setSwitchLoader((x) => ({ ...x, [item.id]: true }));
        if (checked) {
            await userServiceApi.post({
                providedServiceId: item.id,
                userId: props.userId,
            });
        } else {
            await userServiceApi.delete(item.userServiceId!);
        }
        await loadUserServices();
        setSwitchLoader((x) => ({ ...x, [item.id]: false }));
    };
    useEffect(() => {
        loadUserServices();
    }, [loadUserServices]);

    return (
        <>
            {!allServices ? (
                <Row justify={'center'}>
                    <Skeleton />
                </Row>
            ) : (
                <List
                    loading={!allServices}
                    dataSource={allServices}
                    grid={{
                        gutter: 10,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 6,
                        xxl: 3,
                    }}
                    renderItem={(item, index) => (
                        <List.Item
                            actions={[
                                <Switch
                                    loading={switchLoader[item.id]}
                                    checked={item.isChecked}
                                    onChange={(checked, e) => {
                                        onChangeAsync(checked, item);
                                    }}
                                />,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={<>{item.emoji}</>}
                                title={<Typography.Link>{item.name}</Typography.Link>}
                                description={IntlUtility.formatAsMinute(
                                    i18n.language,
                                    item.duration
                                )}
                            />
                        </List.Item>
                    )}
                />
            )}
        </>
    );
};
