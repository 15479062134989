const getEnv = () => {
    if (process.env.NODE_ENV === 'development') {
        if (window.location.host.includes('192')) {
            window.__env__.API_URL = window.location.origin
                .replace(':3000', ':5000')
                .replace('http', 'http');
        }
    }

    return window.__env__;
};

export const ENV = getEnv();

export const NotificationHubUrl = `${ENV.API_URL}/Notifications`;
