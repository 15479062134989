import dayjs from 'dayjs';

import { Row, Skeleton, message, theme } from 'antd';

import { FC, useCallback, useEffect, useState } from 'react';
import {
    Calendar,
    EventWrapperProps,
    HeaderProps,
    SlotInfo,
    dayjsLocalizer,
} from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useShiftApi } from '../../../api/server.controller.context';
import { GetUsersResponseDto } from '../../../api/user/res/get-user-res.dto';
import { GetUsersShiftResponseDto } from '../../../api/user/res/get-user-shift-res.dto';
import { ArrayUtility } from '../../../utils/ArrayUtility';
import { CalendarUtility } from '../../../utils/CalendarUtility';
import { DateUtility } from '../../../utils/DateUtility';
import { CustomCalendarAppEventComponent } from './custom-calendar-app-event.component';
import { CalendarEvent } from './types';
import { UserCreateShiftCalendarModalComponent } from './user-create-shifts-calendar-modal.component';
import { UserUpdateShiftCalendarComponent } from './user-update-shift-calendar.component.tsx';
const { useToken } = theme;

interface Props {
    // shifts: GetUsersShiftResponseDto[];
    user: GetUsersResponseDto;
    reload: () => any;
}

const localizer = dayjsLocalizer(dayjs);

export const UserShiftsCalendarComponent: FC<Props> = ({ user, reload }) => {
    const { t, i18n } = useTranslation();
    const shiftApi = useShiftApi();

    let min: Date = new Date(0, 0, 0, 6, 0, 0);
    let max: Date = new Date(0, 0, 0, 23, 0, 0);

    const [shifts, setShifts] = useState<GetUsersShiftResponseDto[]>([]);

    const loadShifts = useCallback(async () => {
        try {
            const response = await shiftApi.getOne(user.id);
            setShifts(response.data);
        } catch (error) {
            console.log({ error });
        }
    }, [shiftApi, user]);

    useEffect(() => {
        loadShifts();
    }, [loadShifts]);

    const calendarEvents: CalendarEvent[] | undefined = shifts?.map((x, i) => {
        const start = DateUtility.asAbsoluteLocalDate(x.from).javascriptDate;
        const end = DateUtility.asAbsoluteLocalDate(x.to).javascriptDate;

        const mapped = {
            start,
            end,
            shift: x,
            allShifts: shifts,
            index: i,
            user: user,
            resource: x,
        };
        return mapped;
    });

    const eventContainerWrapper: React.ComponentType = function (props) {
        const children = (props as any).children;
        return <div style={{ backgroundColor: 'cyan' }}>{children}</div>;
    };

    const eventWrapper: React.ComponentType<EventWrapperProps<CalendarEvent>> = (props) => {
        const children = (props as any).children;
        let style = props.style || {};
        if (props.event.shift.isVacation) {
            style = {
                ...style,
                border: '3px solid red',
                zIndex: 100,
            };
        }
        return <div style={style}>{children}</div>;
    };

    const deleteAsync = async (event: CalendarEvent) => {
        try {
            await shiftApi.delete(event.shift.id);
            // delete from memory
            setShifts((x) => {
                x?.splice(event.index, 1);
                return [...x];
            });
            message.success(t`Deleted`);
            //reload();
        } catch (error) {
            message.error(t`deleteFailed`);
        }
    };

    const [editEvent, setEditEvent] = useState<CalendarEvent>();
    const onEditClickAsync = (event: CalendarEvent) => {
        setEditEvent(event);
    };

    const [slotInfo, setSlotInfo] = useState<SlotInfo>();
    const onSelectSlot = (slotInfo: SlotInfo) => {
        setSlotInfo(slotInfo);
    };

    const theme = useToken();

    const CustomHeader = (props: HeaderProps) => {
        const data = CalendarUtility.getBackgroundsForCells(theme);
        return <div style={{ ...data.style }}>{props.label}</div>;
    };

    return (
        <>
            {!calendarEvents ? (
                <Row justify={'center'}>
                    <Skeleton />
                </Row>
            ) : (
                <>
                    <UserCreateShiftCalendarModalComponent
                        user={user}
                        slotInfo={slotInfo}
                        onModalClose={(dto) => {
                            setSlotInfo(undefined);
                            if (dto && dto.length) {
                                setShifts((x) => {
                                    const extendedArray = x.concat(dto);
                                    return extendedArray;
                                });
                            }
                        }}
                    />
                    {editEvent && (
                        <UserUpdateShiftCalendarComponent
                            user={user}
                            calendarEvent={editEvent}
                            onDeleteClickedAsync={async (x) => {
                                await deleteAsync(x);
                                setEditEvent(undefined);
                            }}
                            onModalClose={(updatedShift) => {
                                setEditEvent(undefined);
                                if (updatedShift && updatedShift.from && updatedShift.to) {
                                    setShifts((x) => {
                                        ArrayUtility.UpdateElementByReferenceOrIdentifier(
                                            x,
                                            {
                                                from: updatedShift.from,
                                                to: updatedShift.to,
                                                id: updatedShift.id,
                                                isVacation: updatedShift.isVacation,
                                            },
                                            (x) => {
                                                return x.id === updatedShift.id;
                                            }
                                        );
                                        return [...x];
                                    });
                                }
                            }}
                        />
                    )}
                    <Calendar
                        dayPropGetter={CalendarUtility.getBackgroundStyle}
                        slotGroupPropGetter={() => CalendarUtility.getBackgroundsForCells(theme)}
                        min={min}
                        max={max}
                        eventPropGetter={(x) => CalendarUtility.getEventStyle(x.user.colorString)}
                        selectable
                        popup
                        onSelectSlot={onSelectSlot}
                        onSelectEvent={onEditClickAsync}
                        startAccessor={'start'}
                        endAccessor={'end'}
                        localizer={localizer}
                        defaultView="week"
                        views={{ week: true, day: true }}
                        events={calendarEvents}
                        components={{
                            week: {
                                header: CustomHeader,
                            },
                            event: (x) =>
                                CustomCalendarAppEventComponent({
                                    ...x,
                                }),
                            eventContainerWrapper,
                            eventWrapper,
                            // timeSlotWrapper: (x) => {
                            //     const props = x as any;
                            //     console.log({ props22: props });
                            //     return <span>{props.children}</span>;
                            // },
                            // timeGutterWrapper: (x) => {
                            //     // samo sati
                            //     x = x as any;
                            //     return (
                            //         <span style={{ backgroundColor: 'black' }}>
                            //             {' '}
                            //             {(x as any).children}{' '}
                            //         </span>
                            //     );
                            // },

                            // dayColumnWrapper: (x) => {
                            //     const props = x as any;
                            //     console.log({ props });
                            //     return (
                            //         <span
                            //             style={{
                            //                 backgroundColor: token.colorBgContainer,
                            //             }}
                            //             className={props.className}
                            //         >
                            //             {(x as any).children}{' '}
                            //         </span>
                            //     );
                            // },

                            // dateCellWrapper: (x) => {
                            //     const props = x as any;
                            //     console.log(props);
                            //     return <span>{(x as any).children} </span>;
                            // },
                        }}
                    />
                </>
            )}
        </>
    );
};
