import { Select } from 'antd';
import dayjs from 'dayjs';
import enLocale from 'dayjs/locale/en';
import hrLocale from 'dayjs/locale/hr';
import { useTranslation } from 'react-i18next';
import ApplicationConstants from '../application-constants';
import { LocalStorageUtils } from '../utils/LocalStorageUtility';
type Props = {};
dayjs().locale(hrLocale);
dayjs().locale(enLocale);

function setDaysJsLocale(langKey: string) {
    if (!langKey) {
        return;
    }

    if (langKey === 'hr') {
        dayjs().locale('hr-HR');
    } else {
        dayjs().locale('en-US');
    }
}

export const LanguagesDropDownComponent = (props: Props) => {
    const { t, i18n } = useTranslation();
    const currentSavedLang = LocalStorageUtils.retrieve<string>(
        ApplicationConstants.LocalStorage.Keys.SelectedLang
    );
    if (currentSavedLang) {
        setDaysJsLocale(currentSavedLang);
    }

    const changeLang = (lang: any) => {
        i18n.changeLanguage(lang.key);
        LocalStorageUtils.store(ApplicationConstants.LocalStorage.Keys.SelectedLang, lang.key);
        setDaysJsLocale(lang.key);
    };
    return (
        <Select
            labelInValue
            style={{ width: 120 }}
            onChange={changeLang}
            onClick={(x) => x.stopPropagation()}
            defaultValue={t(`${currentSavedLang || 'en'}`)}
            options={[
                {
                    key: 'hr',
                    value: `${t('hr')}`,
                },
                {
                    key: 'en',
                    value: `${t('en')}`,
                },
            ]}
        />
    );
};
