export interface GetUserEmailResDto {
    id: string;
    jsonOrString: string;
    type: MessageTypeEnum;
    state: MessageStateEnum;
    failCount: number;
}
export enum MessageStateEnum {
    Error = 0,
    Pending = 1,
    Sent = 2,
    Received = 3,
    Seen = 4,
    SeenByAll = 5,
    Expired = 6,
}

export enum MessageTypeEnum {
    Registration = 1,
    ContactUs = 2,
    NoReply = 3,
    Sms = 4,
    AdminRegistration = 5,
    Svg = 6,
    ICal = 7,
}
