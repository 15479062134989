import dayjs, { Dayjs } from 'dayjs';
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useState } from 'react';
import { GetReservationRequestType } from './reservation-type-filter.component';

interface AvailableFilters {
    reservationType: GetReservationRequestType;
    dateRange: {
        from?: Dayjs;
        to?: Dayjs;
    };
    search: string;
    selectedWeekValue?: string;
    selectedDayValue?: string;

    publicUserId?: string;
}
interface ReservationFilterContextProps {
    filterState: AvailableFilters;
    setFilterState: Dispatch<SetStateAction<AvailableFilters>>;
}
export const ReservationFilterContext = createContext<ReservationFilterContextProps>(null as any);

export const ReservationFilterContextProvider = ({ children }: PropsWithChildren) => {
    const startOfMonth = dayjs().startOf('month');
    const [filterState, setFilterState] = useState<AvailableFilters>({
        dateRange: {
            from: startOfMonth,
            to: dayjs(startOfMonth).endOf('month'),
        },
        search: '',
        publicUserId: undefined,
        reservationType: GetReservationRequestType.ALL,
    });

    return (
        <ReservationFilterContext.Provider value={{ setFilterState, filterState }}>
            {children}
        </ReservationFilterContext.Provider>
    );
};
