import {
    Card,
    Checkbox,
    Form,
    Popconfirm,
    Space,
    Table,
    Tabs,
    TabsProps,
    Tour,
    Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ExpandableConfig } from 'antd/es/table/interface';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useBackofficeUserApi } from '../../../api/server.controller.context';
import { PatchUserRequestDto } from '../../../api/user/req/patch-user-req.dto';
import { GetUsersResponseDto } from '../../../api/user/res/get-user-res.dto';
import { StringUtility } from '../../../utils/StringUtility';
import { ReservationPage } from '../../reservation/pages/all/reservation.page';
import { UserEmailTableComponent } from './user-email-table.component';

type Props = {
    dataSource: GetUsersResponseDto[] | undefined;
    reload: () => any;
};
type FormType = PatchUserRequestDto & {
    id: string;
    colorString: { toHexString: () => string };
};

export const PublicUserTableComponent = (props: Props) => {
    const api = useBackofficeUserApi();
    const [editingKey, setEditingKey] = useState('');
    const [deletingKey, setDeletingKey] = useState('');
    const isEditing = (record: FormType) => record.id === editingKey;
    const isDeleting = (record: GetUsersResponseDto) => record.id === deletingKey;
    const [form] = useForm<FormType>();
    const { t } = useTranslation();

    const [isTourOpen, openTour] = useState(false);

    const location = useLocation();
    const ref = useRef<HTMLTableRowElement | null>();
    useEffect(() => {
        if (!Boolean(props.dataSource)) {
            return;
        }
        const params = new URLSearchParams(location.search);
        if (params.has('userId')) {
            const value = params.get('userId');
            if (!ref.current) {
                ref.current = document.querySelector<HTMLTableRowElement>(
                    `[data-row-key="${value}"]`
                );
                openTour(true);
            }
        }
    }, [location, props.dataSource]);
    const edit = (record: FormType) => {
        form.setFieldsValue({ ...record });
        setEditingKey(record.id);
    };
    const cancel = () => {
        setEditingKey('');
        setDeletingKey('');
    };
    const saveAsync = async (tableRecord: FormType) => {
        try {
            const values = await form.validateFields();
            let colorString: string = values.colorString;
            if (typeof colorString !== 'string' && 'toHexString' in colorString) {
                colorString = values.colorString.toHexString();
            }
            await api.patch({
                ...values,
                id: editingKey,
                colorString,
            });
            cancel();
            props.reload();
        } catch (error) {}
    };

    const deleteAsync = async (record: FormType) => {
        setDeletingKey(record.id);
        await api.delete(record.id);
        props.reload();
    };

    let columns = [
        {
            title: t`User Name / Email`,
            dataIndex: 'userName',
            key: 'userName',
            render: (userName: string, user: GetUsersResponseDto) => {
                return (
                    <>
                        <div>{userName}</div>
                        <div>{user.email}</div>
                    </>
                );
            },
        },
        {
            title: t`First Name & Last Name`,
            render: (userName: string, user: GetUsersResponseDto) => {
                return (
                    <Space>
                        <span>{user.firstName}</span>
                        <span>{user.lastName}</span>
                    </Space>
                );
            },
        },
        {
            title: t`Email Activated`,
            render: (userName: string, user: GetUsersResponseDto) => {
                return (
                    <Space>
                        <Checkbox checked={Boolean(user.emailConfirmed)} />
                    </Space>
                );
            },
        },

        {
            title: t`Phone Number`,
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: t`Operation`,
            dataIndex: 'operation',
            render: (_a: any, record: FormType) => {
                return (
                    <Space>
                        {/* <Typography.Link
                            onClick={(e) => {
                                e.stopPropagation();
                                saveAsync(record);
                            }}
                        >
                            {t`Edit`}
                        </Typography.Link> */}
                        <Popconfirm
                            title={t`Sure to delete?`}
                            onConfirm={(e) => {
                                e?.stopPropagation();
                                deleteAsync(record);
                            }}
                        >
                            <Typography.Link
                                disabled={editingKey !== ''}
                                onClick={(e) => {
                                    e?.stopPropagation();
                                }}
                            >
                                {t`Delete`}
                            </Typography.Link>
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ];

    const asTabItem = (record: GetUsersResponseDto, index: number) => {
        const items: TabsProps['items'] = [
            {
                key: '1',
                label: t`Users Reservations`,
                children: <ReservationPage filterBySpecificPublicUserId={record.id} />,
            },
            {
                key: '2',
                label: t`Emails`,
                children: <UserEmailTableComponent userId={record.id} />,
            },
        ];
        return items;
    };

    const expandable: ExpandableConfig<GetUsersResponseDto> = {
        expandedRowRender: (record, index, _, expanded) => {
            return (
                <Card
                    style={{
                        border: `2px solid ${
                            StringUtility.getColorStyles(record.colorString).backgroundColor
                        }`,
                    }}
                >
                    <Tabs
                        defaultActiveKey="1"
                        destroyInactiveTabPane
                        items={asTabItem(record, index)}
                    ></Tabs>
                </Card>
            );
        },
    };

    return (
        <Form form={form} component={false}>
            <Table
                rowKey={(x) => x.id}
                columns={columns as any}
                dataSource={props.dataSource ?? []}
                expandable={expandable}
                loading={!Boolean(props.dataSource)}
                scroll={{ x: 1000 }}
            />
            <Tour
                open={isTourOpen}
                onClose={() => openTour(false)}
                steps={[
                    {
                        title: '',
                        target: () => ref.current!,
                        description: t`Clicked user`,
                        nextButtonProps: {
                            children: <></>,
                            style: { display: 'none' },
                        },
                    },
                ]}
            />
        </Form>
    );
};
