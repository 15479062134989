import axios from 'axios';
import { ENV } from '../../env';
import { SubscribableState } from '../../observable/SubscribableState';
import { GetAvailabilitiesReqDto } from './req/get-availabilities-req.dto';
import { GetAvailabilitiesResDto } from './res/get-availabilities-res.dto';

export class AvailabilityApi {
    private readonly Url = `${ENV.API_URL}/api/availability`;

    readonly subscribable: SubscribableState<GetAvailabilitiesResDto[]>;

    constructor() {
        this.subscribable = new SubscribableState([] as GetAvailabilitiesResDto[]);
    }
    async refreshData(dto: GetAvailabilitiesReqDto) {
        const response = await this.get(dto);
        this.subscribable.update(response.data);
    }

    get(dto: GetAvailabilitiesReqDto) {
        const { serviceIds, barberId, ...others } = { ...dto };
        const params = new URLSearchParams(others);
        if (serviceIds) {
            serviceIds.forEach((x) => params.append('serviceIds', x));
        }
        if (barberId) {
            params.append('barberId', barberId);
        }
        return axios.get<GetAvailabilitiesResDto[]>(this.Url, { params });
    }
}
