import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Checkbox, DatePicker, Form, InputNumber, Modal, message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { useEffect, useMemo } from 'react';
import { SlotInfo } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useShiftApi } from '../../../api/server.controller.context';
import { PostShiftRequestDto } from '../../../api/shift/req/post-shift-req.dto';
import type { PostShiftResponse } from '../../../api/shift/res/post-shift-response';
import { GetUsersResponseDto } from '../../../api/user/res/get-user-res.dto';
import { CalendarUtility } from '../../../utils/CalendarUtility';
import { DateUtility } from '../../../utils/DateUtility';
import { CalendarEvent } from './types';

type Props = {
    onModalClose: (dto?: PostShiftResponse[]) => void | Promise<void>;
    user: GetUsersResponseDto;
    calendarEvent?: CalendarEvent;
    slotInfo: SlotInfo | undefined;
};
interface FormValues {
    from?: Dayjs;
    to?: Dayjs;
    isVacation: boolean;
    amountOfWeeks: number;
}

export const UserCreateShiftCalendarModalComponent = (props: Props) => {
    const { t } = useTranslation();
    const api = useShiftApi();

    const saveAsync = async () => {
        try {
            const values = await form.validateFields();
            const from = DateUtility.asAbsoluteUtcString(values.from!);
            const to = DateUtility.asAbsoluteUtcString(values.to!);
            // const from = values!.from!.toISOString();
            // const to = values!.to!.toISOString();
            const createItem: PostShiftRequestDto = {
                amountOfWeeks: values.amountOfWeeks,
                from,
                to,
                userId: props.user.id,
                isVacation: values.isVacation,
            };
            const response = await api.post(createItem);
            message.success(t`Saved successfully`);
            props.onModalClose(response.data);
        } catch (error) {
            if (error && typeof error === 'object' && 'errorFields' in error) {
                return;
            }
            // message.error(t`possibleOverlap`);
        }
    };

    const beforeModalClose = () => {
        props.onModalClose();
    };
    const [form] = Form.useForm<FormValues>();

    const initialValues: FormValues = useMemo(
        () => ({
            from: dayjs(props.slotInfo?.start),
            to: dayjs(props.slotInfo?.end),
            isVacation: false,
            amountOfWeeks: 0,
        }),
        [props]
    );
    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [initialValues, form]);

    return (
        <Modal
            width={'fit-content'}
            destroyOnClose
            open={Boolean(props.slotInfo)}
            onCancel={props.onModalClose as any}
            afterClose={beforeModalClose}
            footer={[
                <Button key="cancel" onClick={beforeModalClose}>
                    {t`Cancel`}
                </Button>,
                <Button key="add" type="primary" onClick={saveAsync}>
                    {t`Save`}
                </Button>,
            ]}
        >
            <Avatar
                size="large"
                icon={<UserOutlined />}
                style={{ ...CalendarUtility.getEventStyle(props.user.colorString).style }}
            />
            {props.user.userName}
            <Form form={form} labelCol={{ span: 12 }}>
                <Form.Item
                    name="from"
                    label={t`From`}
                    rules={[{ required: true, message: t`Please select a From date` as string }]}
                >
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item
                    name="to"
                    label={t`To`}
                    rules={[{ required: true, message: t`Please select a To date` as string }]}
                >
                    <DatePicker showTime />
                </Form.Item>
                <Form.Item label={t`Is Vacation`} name="isVacation" valuePropName="checked">
                    <Checkbox />
                </Form.Item>
                <Form.Item name="amountOfWeeks" label={t`Amount of weeks`}>
                    <InputNumber min={0} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
