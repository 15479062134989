import axios from 'axios';
import { ENV } from '../../env';
import { PatchWaitingListReqDto } from './req/patch-waiting-list-req.dto';
import { PostWaitingListReqDto } from './req/post-waiting-list-req.dto';
import { GetWaitingListResDto } from './res/get-waiting-list-res.dto';

export class WaitingListApi {
    private readonly Url = `${ENV.API_URL}/api/WaitingList`;

    get() {
        return axios.get<GetWaitingListResDto[]>(this.Url);
    }

    post(dto: PostWaitingListReqDto) {
        return axios.post(this.Url, dto);
    }
    patch(dto: PatchWaitingListReqDto) {
        return axios.patch(this.Url, dto);
    }
    delete(id: string) {
        const params = new URLSearchParams();
        params.append('id', id);
        return axios.delete(this.Url, { params });
    }
}
