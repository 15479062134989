import { Button, Col, Modal, Row, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackofficeUserApi } from '../../api/server.controller.context';
import { GetUsersResponseDto } from '../../api/user/res/get-user-res.dto';
import { BackofficeUserTableComponent } from './components/backoffice-user-table.component';
import { CreateBackofficeUserFormComponent } from './components/create-backoffice-user-form.component';

type Props = {};
const { Title } = Typography;
export const BackofficeUserPage = (props: Props) => {
    const api = useBackofficeUserApi();
    const [dataSource, setDataSource] = useState<GetUsersResponseDto[] | undefined>(undefined);
    const [editableUser, setEditableUser] = useState<GetUsersResponseDto | undefined>(undefined);
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);

    const handleModalOpen = () => {
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setEditableUser(undefined);
        setModalVisible(false);
    };

    const load = useCallback(async () => {
        setDataSource(undefined);
        const response = await api.get();
        setDataSource(response.data);
    }, [api]);

    useEffect(() => {
        load();
    }, [load]);

    return (
        <>
            <Row
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                className="my-3 me-2"
            >
                <Col>
                    <Title level={2}>{t`Barber List`}</Title>
                </Col>
                <Col>
                    <Button type="primary" onClick={handleModalOpen}>
                        {t`Create New`}
                    </Button>
                </Col>
            </Row>

            <Modal
                destroyOnClose
                open={modalVisible}
                onCancel={handleModalClose}
                closable
                footer={[
                    <Button key="cancel" onClick={handleModalClose}>
                        {t`Cancel`}
                    </Button>,
                    <Button key="add" type="primary" form="form" htmlType="submit">
                        {/* Will submit the form */}
                        {t`Save`}
                    </Button>,
                ]}
            >
                <CreateBackofficeUserFormComponent
                    onSubmitAsync={async (x) => {
                        if (editableUser) {
                            await api.patch({
                                firstName: x.firstName!,
                                lastName: x.lastName!,
                                password: x.password!,
                                accountType: x.accountType!,
                                colorString: x.colorString!,
                                confirmPassword: x.confirmPassword!,
                                username: x.userName!,
                                id: editableUser.id,
                            });
                        } else {
                            await api.post({
                                firstName: x.firstName!,
                                lastName: x.lastName!,
                                password: x.password!,
                                accountType: x.accountType!,
                                colorString: x.colorString!,
                                confirmPassword: x.confirmPassword!,
                                username: x.userName!,
                                serviceIds: x.serviceIds!,
                            });
                        }

                        setEditableUser(undefined);
                        setModalVisible(false);
                        await load();
                    }}
                    editableEntity={editableUser}
                />
            </Modal>
            <BackofficeUserTableComponent
                dataSource={dataSource}
                reload={load}
                onEditClick={(entity) => {
                    setEditableUser(entity);
                    handleModalOpen();
                }}
            />
        </>
    );
};
