import { Card } from 'antd';
import { ElementType } from 'react';
import { GetAvailabilitiesResDto } from '../../api/availability/res/get-availabilities-res.dto';
import { AvailabilityContextPage } from './availability-context.page';
import { AvailabilityFiltersContext } from './availability-filters.context';

type Props = {
    as?: ElementType;
    onRow?: (
        data: GetAvailabilitiesResDto,
        index?: number
    ) => React.HTMLAttributes<any> | React.TdHTMLAttributes<any>;
    config?: {
        hideServicesFilter?: boolean;
        hideCreateReservationButton?: boolean;
    };
};

export const AvailabilityPage = (props: Props) => {
    return (
        <AvailabilityFiltersContext>
            <AvailabilityContextPage {...props} as={props.as ?? Card} />
        </AvailabilityFiltersContext>
    );
};
