import dayjs, { Dayjs } from 'dayjs';

export class DateUtility {
    static toIntlWeeks(_this: Date, numberOfWeeks: number, locales: string | string[]) {
        const weeks: any[] = [];
        let counter = 0;
        for (
            let from = dayjs(_this).startOf('week').toDate();
            from <= dayjs(_this).add(numberOfWeeks, 'weeks').startOf('day').toDate();
            from = dayjs(from).add(1, 'week').startOf('day').toDate()
        ) {
            const to = dayjs(from).add(1, 'week');
            const name = DateUtility.getRelativeTimeFormat(locales, 'week', counter);

            weeks.push({
                name: name,
                from: dayjs(from),
                to,
            });
            ++counter;
        }
        return weeks;
    }

    static toIntlDays(_this: Date, numberOfDays: number, locales: string | string[]) {
        const days: any[] = [];
        let counter = 0;
        for (
            let from = dayjs(_this).startOf('day').toDate();
            from <= dayjs(_this).add(numberOfDays, 'days').toDate();
            from = dayjs(from).add(1, 'day').toDate()
        ) {
            const name = DateUtility.getRelativeTimeFormat(locales, 'day', counter);

            days.push({
                from: dayjs(from),
                to: dayjs(from).add(1, 'day').startOf('day'),
                name,
            });
            ++counter;
        }
        return days;
    }
    static getRelativeTimeFormat(
        language: string | string[],
        unit: Intl.RelativeTimeFormatUnit,
        value: number | string
    ) {
        const relativeDateTimeFormat = new Intl.RelativeTimeFormat(language, { numeric: 'auto' });
        return relativeDateTimeFormat.format(+value, unit);
    }

    static getCurrentWeekStartAndEnd() {
        const today = dayjs();

        const startOfWeek = today.startOf('week');

        const endOfWeek = today.endOf('week');

        return { start: startOfWeek, end: endOfWeek };
    }

    static addWeeks(numberOfWeeks: number) {
        const start = dayjs();

        const end = start.add(numberOfWeeks, 'weeks');

        return { start, end };
    }

    static getDateTimezone(date: string) {
        const timeZone = date.slice(-6);
        if (timeZone.includes(':')) {
            return null;
        }
        return timeZone;
    }

    static addHoursBasedOnTimeZone(date: string) {}

    static asAbsoluteUtcString(date: Dayjs) {
        return date.format('YYYY-MM-DDTHH:mm:ss');
        // this method used to convert any time to utc by replacing the values of the timezone
        return date.format('YYYY-MM-DDTHH:mm:ss') + 'Z';
    }
    static asAbsoluteLocalDate(date: string) {
        const offset = DateUtility.getDateTimezone(date);
        if (offset === null) {
            // date without timezone
            const dayJsDate = dayjs(dayjs(date).format('YYYY-MM-DDTHH:mm:ss'));
            return { javascriptDate: dayJsDate.toDate(), dayJsDate };
        }
        const offsetNumber = +offset.replace(':00', '');
        const dayJsDate = dayjs(
            dayjs(date)
                .utcOffset(offset)
                .add(-1 * offsetNumber, 'hours')
                .format('YYYY-MM-DDTHH:mm:ss')
        );
        return { javascriptDate: dayJsDate.toDate(), dayJsDate };
    }
}
