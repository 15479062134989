import dayjs, { Dayjs } from 'dayjs';
import React, { Dispatch, PropsWithChildren, SetStateAction, createContext, useState } from 'react';

interface AvailableFilters {
    dateRange: {
        from: Dayjs;
        to: Dayjs;
    };
    search: string;
    serviceIds: string[];
    barberId: string;
    selectedWeekValue?: string;
    selectedDayValue?: string;
    selectedBarberId?: string;
    isDateFromOpen?: boolean;
    isDateToOpen?: boolean;
}
interface FilterContextProps {
    filterState: AvailableFilters;
    setFilterState: Dispatch<SetStateAction<AvailableFilters>>;
}
export const AvailabilityFilterContext = createContext<FilterContextProps>(null as any);
type Props = {
    value?: any;
    onChange?: (...values: any[]) => any;
    initialValues?: Partial<AvailableFilters>;
};
export const AvailabilityFiltersContext = ({
    children,
    value,
    onChange,
    initialValues,
}: PropsWithChildren<Props>) => {
    const now = dayjs().startOf('day');
    const [filterState, setFilterState] = useState<AvailableFilters>({
        dateRange: initialValues?.dateRange || {
            from: now,
            to: dayjs(now).startOf('day').add(2, 'days'),
        },

        search: initialValues?.search || '',
        serviceIds: initialValues?.serviceIds || [],
        barberId: initialValues?.barberId || '',
    });
    return (
        <AvailabilityFilterContext.Provider value={{ setFilterState, filterState }}>
            {React.cloneElement(children as any, { value, onChange }, children)}
        </AvailabilityFilterContext.Provider>
    );
};
