import axios from 'axios';
import { ENV } from '../../env';
import type { PostPublicUserReqDto } from './req/post-public-user-req.dto';

export class PublicUserApi {
    private readonly Url = `${ENV.API_URL}/api/publicUser`;

    post(dto: PostPublicUserReqDto) {
        return axios.post(this.Url, dto);
    }
}
