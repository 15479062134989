import { useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { useUserState } from '../api/server.controller.context';
import { GetRoutesBasedOfRole } from './route-definitions';

export const ApplicationRoutes = () => {
    const { role } = useUserState();

    const routesBasedOfRole = useMemo(() => GetRoutesBasedOfRole(role), [role]);
    return (
        <Routes>
            {routesBasedOfRole.map((x) => {
                return (
                    <Route key={x.path} path={x.path} element={x.element}>
                        {x.subRoutes?.map((subRoute) => {
                            const isIndexRoute = subRoute.path === '';
                            if (isIndexRoute) {
                                return (
                                    <Route key={subRoute.path} index element={subRoute.element} />
                                );
                            }
                            return (
                                <Route
                                    key={x.path + subRoute.path}
                                    path={x.path + subRoute.path}
                                    element={subRoute.element}
                                />
                            );
                        })}
                    </Route>
                );
            })}
            <Route path="*" element={<Navigate to="/reservations/overview#calendarView" />} />
        </Routes>
    );
};
