import { ClockCircleOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { Calendar, Event, NavigateAction, View, dayjsLocalizer } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import type { GetReservationResDto } from '../../../api/reservation/res/get-reservation-res.dto';
import { useSubscribableTheme } from '../../../app-config.context';
import ApplicationConstants from '../../../application-constants';
import { CalendarUtility } from '../../../utils/CalendarUtility';
import { DateUtility } from '../../../utils/DateUtility';
import { LocalStorageUtils } from '../../../utils/LocalStorageUtility';
import { StringUtility } from '../../../utils/StringUtility';

export type RangeChangeProps = (
    range: Date[] | { start: Date; end: Date },
    view?: View,
    currentDate?: Date
) => void;
type Props = {
    dataSource: GetReservationResDto[];
    onRangeChange?: RangeChangeProps;
    currentDate?: Date;
    onEventClick?: (event: GetReservationResDto) => void;
    onDeleteCompleted?: (resource: GetReservationResDto) => void;
};
const localizer = dayjsLocalizer(dayjs);

export const ReservationCalendarComponent = (props: Props) => {
    const { t } = useTranslation();
    const selectedView: View =
        LocalStorageUtils.retrieve(ApplicationConstants.LocalStorage.Keys.CalendarView) || 'day';

    const calendarLocalizationMessages = useMemo(() => {
        return {
            date: t`Date`,
            time: t`Time`,
            event: t`Event`,
            allDay: t`AllDay`,
            week: t`Week`,
            work_week: t`WorkWeek`,
            day: t`Day`,
            month: t`Month`,
            previous: t`Previous`,
            next: t`Next`,
            yesterday: t`Yesterday`,
            tomorrow: t`Tomorrow`,
            today: t`Today`,
            agenda: t`Agenda`,
            noEventsInRange: t`NoEventsInRage`,
            showMore: (total: number) => `+${total} ${t('More')}`,
        };
    }, [t]);
    let currentDate = props.currentDate;
    const events: Event[] = props.dataSource.map((x) => ({
        allDay: false,
        resource: x,
        start: DateUtility.asAbsoluteLocalDate(x.from).javascriptDate,
        end: DateUtility.asAbsoluteLocalDate(x.to).javascriptDate,
    }));

    let minDate: Date | undefined = new Date(0, 0, 0, 8, 0.0);
    let maxDate: Date | undefined = new Date(0, 0, 0, 22, 0.0);

    // events.forEach((x) => {
    //     const from = x.start!;
    //     const to = x.end!;
    //     // Get min and max only for selected day
    //     if (selectedView === 'day' && currentDate) {
    //         const date = currentDate.getDate();
    //         const year = currentDate.getFullYear();
    //         const day = currentDate.getDay();

    //         const fromDate = from.getDate();
    //         const fromYear = from.getFullYear();
    //         const fromDay = from.getDay();
    //         if (fromDate !== date || fromDay !== day || fromYear !== year) {
    //             return;
    //         }
    //     }
    //     if (!minDate || from.getTime() < minDate.getTime()) {
    //         minDate = from;
    //     }
    //     if (!maxDate || to.getTime() > maxDate.getTime()) {
    //         maxDate = to;
    //     }
    // });

    const onRangeChange: RangeChangeProps = (
        range: Date[] | { start: Date; end: Date },
        view?: View
    ) => {
        props?.onRangeChange?.(range, view, currentDate);
    };
    const onNavigate = (newDate: Date, view: View, action: NavigateAction) => {
        currentDate = newDate;
    };
    if (events.length === 1) {
    }
    const onSelectEvent = (event: Event, e?: React.SyntheticEvent<HTMLElement>) => {
        props?.onEventClick?.(event.resource);
    };
    const theme = useSubscribableTheme();
    return (
        <Calendar
            style={{ height: '100vh', backgroundColor: theme.token?.colorBgLayout ?? 'white' }}
            date={currentDate}
            slotGroupPropGetter={() => CalendarUtility.getBackgroundsForCells(theme)}
            min={minDate}
            max={maxDate}
            onNavigate={onNavigate}
            onView={(x) => {
                LocalStorageUtils.store(ApplicationConstants.LocalStorage.Keys.CalendarView, x);
            }}
            onSelectEvent={onSelectEvent}
            eventPropGetter={(e) => {
                const resource = e.resource as GetReservationResDto;
                const style = CalendarUtility.getEventStyle(resource.user.colorString);
                return style;
            }}
            events={events}
            defaultView={selectedView}
            localizer={localizer}
            messages={calendarLocalizationMessages}
            selectable
            views={{ month: true, day: true }}
            showMultiDayTimes
            onRangeChange={onRangeChange}
            components={{
                // agenda: undefined,
                // header: undefined,
                // resourceHeader: undefined,
                // day: undefined,
                event: (...args) => {
                    const props = args[0];
                    const resource = props.event.resource as GetReservationResDto;
                    return (
                        <div style={{ position: 'relative' }}>
                            {resource.publicClient && (
                                <Badge
                                    count={
                                        <ClockCircleOutlined
                                            style={{
                                                color: StringUtility.getContrastYIQ(
                                                    resource.user.colorString
                                                ),
                                            }}
                                        />
                                    }
                                ></Badge>
                            )}
                            {resource.providedServices.map((x) => x.emoji || '')}
                            {resource.publicClient
                                ? `${resource.publicClient.firstName} ${resource.publicClient.lastName}`
                                : resource.name}
                        </div>
                    );
                },
            }}
        />
    );
};
