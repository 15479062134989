import { Switch } from 'antd';

import { useTranslation } from 'react-i18next';
import { useAppTheme, useSubscribableTheme } from '../app-config.context';

type Props = {};

export const ThemeToggleComponent = (props: Props) => {
    const appTheme = useAppTheme();
    const theme = useSubscribableTheme();
    const { t } = useTranslation();
    const toggleTheme = (isLight: boolean) => {
        if (isLight) {
            const empty = Object.assign({});
            appTheme.subscribable.update(empty as Partial<any>);
            return;
        }
        appTheme.subscribable.update({
            token: {
                colorPrimary: '#fff',
                fontFamily: 'Josefin Sans',
                fontWeightStrong: 600,
                colorText: 'rgba(255, 255, 255, 0.65)',
                colorTextSecondary: '#A5A5A5',
                colorTextHeading: '#fff',
                colorTextDisabled: '#e159e5',
                colorIcon: '#fff',
                colorLink: '#fff',
                colorTextLightSolid: '#000',
                colorTextPlaceholder: 'rgba(255, 255, 255, 0.85)',
                controlItemBgActive: '#e159e5',
                borderRadius: 16,

                // Layout
                colorBgLayout: '#1e1f22',
                colorBgContainer: 'rgba(41, 42, 45, 1)',
                colorBgElevated: '#1f1f1f',
                colorBorder: 'rgba(255, 255, 255, 0.18)',
                colorBorderSecondary: 'rgba(255, 255, 255, 0.18)',

                // Buttons
            },
            components: {
                Button: {
                    colorPrimary: '#e159e5',
                    colorTextLightSolid: '#E5E5E5',
                },
                Tabs: {
                    inkBarColor: '#e159e5',
                    itemSelectedColor: '#fff',
                },
                Menu: {
                    colorBgContainer: 'transparent',
                    colorText: '#fff',
                    colorItemTextSelected: '#fff',
                },
            },
            isDark: true,
        });
    };
    return (
        <div>
            <Switch
                onChange={toggleTheme}
                checkedChildren={<span className="text-white">{t`Light`}</span>}
                unCheckedChildren={<span className="text-white">{t`Dark`}</span>}
                defaultChecked={!theme.isDark}
            />
        </div>
    );
};
