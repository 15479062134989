import axios from 'axios';
import { ENV } from '../../env';
import type { GetUserEmailResDto } from './res/get-user-email-res.dto';

export class UserEmailApi {
    private readonly Url = `${ENV.API_URL}/api/UserEmail`;

    get(userId: string) {
        return axios.get<GetUserEmailResDto[]>(this.Url + `/${userId}`);
    }

    delete(id: string) {
        const params = new URLSearchParams();
        params.append('id', id);
        return axios.delete(this.Url, { params });
    }
}
