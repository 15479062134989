import { Button, Col, Modal, Row, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackofficeUserApi, usePublicUserApi } from '../../api/server.controller.context';
import { PostPublicUserReqDto } from '../../api/user/req/post-public-user-req.dto';
import { GetUsersResponseDto } from '../../api/user/res/get-user-res.dto';
import { CreatePublicUserFormComponent } from './components/create-public-user-form.component';
import { PublicUserTableComponent } from './components/public-user-table.component';

type Props = {};

const { Title } = Typography;

export const PublicUserPage = (props: Props) => {
    const publicUserApi = usePublicUserApi();
    const backofficeUserApi = useBackofficeUserApi();
    const [dataSource, setDataSource] = useState<GetUsersResponseDto[] | undefined>(undefined);
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);

    const handleModalOpen = () => {
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };

    const load = useCallback(async () => {
        setDataSource(undefined);
        const response = await backofficeUserApi.getPublicUsers();
        setDataSource(response.data);
    }, [backofficeUserApi]);

    const onSubmitAsync = async (x: PostPublicUserReqDto) => {
        await publicUserApi.post(x);
        setModalVisible(false);
        await load();
    };

    useEffect(() => {
        load();
    }, [load]);

    return (
        <>
            <Row
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                className="my-3 me-2"
            >
                <Col>
                    <Title level={2}>Lista korisnika</Title>
                </Col>
                <Col>
                    <Button type="primary" onClick={handleModalOpen}>
                        {t`Create New`}
                    </Button>
                </Col>
            </Row>

            <Modal
                destroyOnClose={true}
                open={modalVisible}
                onCancel={handleModalClose}
                footer={[
                    <Button key="cancel" onClick={handleModalClose}>
                        {t`Cancel`}
                    </Button>,
                    <Button key="add" type="primary" form="form" htmlType="submit">
                        {/* Will submit the form */}
                        {t`Save`}
                    </Button>,
                ]}
            >
                <CreatePublicUserFormComponent onSubmit={onSubmitAsync} />
            </Modal>
            <PublicUserTableComponent dataSource={dataSource} reload={load} />
        </>
    );
};
