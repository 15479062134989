import { GetReservationResDto } from '../../../../api/reservation/res/get-reservation-res.dto';
import { ReservationCreatePage } from '../create/reservation-create.page';

type Props = {
    data?: GetReservationResDto;
    onEditFinished?: () => void;
};

export const ReservationEditPage = (props: Props) => {
    return <ReservationCreatePage data={props.data} onEditFinished={props.onEditFinished} />;
};
