import { Col, DatePicker, Row } from 'antd';
import enLocale from 'antd/es/date-picker/locale/en_US';
import hrLocale from 'antd/es/date-picker/locale/hr_HR';
import { Dayjs } from 'dayjs';
import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailabilityFilterContext } from '../availability-filters.context';

type Props = { style?: React.CSSProperties; className?: string };

export const AvailabilityPickDateDateRangeComponent = memo((props: Props) => {
    const { setFilterState, filterState } = useContext(AvailabilityFilterContext);

    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const handleToChange = (date: Dayjs) => {
        if (!date) {
            return;
        }
        setFilterState((x) => {
            return {
                ...x,
                dateRange: {
                    from: x.dateRange.from,
                    to: date,
                },
                selectedDayValue: undefined,
                selectedWeekValue: undefined,
                isDateToOpen: false,
            };
        });
    };

    const handleFromChange = (date: Dayjs) => {
        if (!date) {
            return;
        }
        setFilterState((x) => {
            const to = x.dateRange.from.add(2, 'days');
            return {
                ...x,
                dateRange: {
                    from: date,
                    to,
                },
                selectedDayValue: undefined,
                selectedWeekValue: undefined,
                isDateFromOpen: false,
            };
        });
    };

    return (
        <Row>
            <Col md={3} xxl={3} xl={3} xs={12}>
                <DatePicker
                    locale={i18n.language === 'hr' ? hrLocale : enLocale}
                    {...props}
                    onChange={handleFromChange as any}
                    allowClear={false}
                    showToday={false}
                    onBlur={(_) => {
                        setFilterState((x) => ({
                            ...x,
                            isDateFromOpen: false,
                        }));
                    }}
                    open={filterState.isDateFromOpen}
                    onSelect={handleFromChange as any}
                    onClick={() => {
                        if (!filterState.isDateFromOpen) {
                            setFilterState((x) => ({
                                ...x,
                                isDateFromOpen: true,
                                isDateToOpen: false,
                            }));
                        }
                    }}
                    value={filterState.dateRange.from}
                ></DatePicker>
            </Col>
            <Col md={3} xxl={3} xl={3} xs={12}>
                <DatePicker
                    showToday={false}
                    locale={i18n.language === 'hr' ? hrLocale : enLocale}
                    {...props}
                    onOpenChange={() => {
                        return;
                    }}
                    onChange={handleToChange as any}
                    onSelect={handleToChange as any}
                    allowClear={false}
                    onBlur={(_) => {
                        setFilterState((x) => ({
                            ...x,
                            isDateToOpen: false,
                        }));
                    }}
                    open={filterState.isDateToOpen}
                    onClick={() => {
                        if (!filterState.isDateToOpen) {
                            setFilterState((x) => ({
                                ...x,
                                isDateToOpen: true,
                                isDateFromOpen: false,
                            }));
                        }
                    }}
                    value={filterState.dateRange.to}
                ></DatePicker>
            </Col>
        </Row>
    );
});
