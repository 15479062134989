import { Card, Col, ConfigProvider, Layout, Row, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { useSubscribableTheme } from './app-config.context';
import { AuthGuardComponent } from './auth/auth-guard.component';
import { LanguagesDropDownComponent } from './components/languages-dropdown.component';
import { LoginPage } from './pages/login/login-page';
import { MainLayout } from './routes/main-layout';

export const App: React.FC = () => {
    const themeConfig = useSubscribableTheme();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#53b0f9',
                        fontFamily: 'Josefin Sans',
                        fontWeightStrong: 600,
                        colorText: 'rgba(0, 0, 0, 0.65)',
                        colorTextSecondary: '#A5A5A5',
                        colorTextHeading: '#000',
                        colorTextDisabled: '#222',
                        colorIcon: '#000',
                        colorTextLightSolid: '#000',
                        colorTextPlaceholder: 'rgba(0, 0, 0, 0.85)',
                        controlItemBgActive: '#e9edf5',
                        borderRadius: 16,

                        // Layout
                        colorBgLayout: '#e9edf5',
                        colorBgContainer: '#fff',
                        colorBgElevated: '#fff',
                        colorBorder: 'rgba(0, 0, 0, 0.18)',
                        colorBorderSecondary: 'rgba(0, 0, 0, 0.18)',
                    },
                    components: {
                        Button: {
                            colorPrimary: '#333',
                            colorTextLightSolid: '#E5E5E5',
                        },
                        Tabs: {
                            inkBarColor: '#333',
                            itemSelectedColor: '#000',
                        },
                        Menu: {
                            colorBgContainer: '#fff',
                            colorText: '#333',
                            colorItemTextSelected: '#333',
                        },
                        Slider: {
                            colorBgContainer: '#fff',
                        },
                    },
                }}
            >
                <ConfigProvider theme={themeConfig}>
                    <BrowserRouter key="browser-router">
                        <AuthGuardComponent
                            key="AuthGuardComponent"
                            isAuthenticated={<MainLayout />}
                            else={
                                <Layout style={{ minHeight: '100vh' }}>
                                    <Content>
                                        <Row
                                            justify={'center'}
                                            align={'middle'}
                                            style={{ minHeight: '100vh' }}
                                        >
                                            <Col>
                                                <div className="d-flex align-items-center justify-content-center logo-container border-bottom-main">
                                                    <div className="logo-wrap d-flex align-items-center">
                                                        {themeConfig.isDark ? (
                                                            <img
                                                                src="/rezervirajse.png"
                                                                width={180}
                                                                alt="logo"
                                                            />
                                                        ) : (
                                                            <img
                                                                src="/rezervirajsedark.png"
                                                                width={180}
                                                                alt="logo"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <Card hoverable>
                                                    <LoginPage />
                                                </Card>
                                                <Row
                                                    justify={'center'}
                                                    align={'middle'}
                                                    style={{ marginTop: '1rem' }}
                                                >
                                                    <Col>
                                                        <LanguagesDropDownComponent />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Content>
                                </Layout>
                            }
                        />
                    </BrowserRouter>
                </ConfigProvider>
            </ConfigProvider>
        </>
    );
};
