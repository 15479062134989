import { EventProps } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { CalendarEvent } from './types';

type Props = EventProps<CalendarEvent> & {};
export const CustomCalendarAppEventComponent = (props: Props) => {
    const resource = props.event.user;
    const { t } = useTranslation();

    return (
        <>
            {resource.userName}{' '}
            <b style={{ textTransform: 'uppercase' }}>
                {props.event.shift.isVacation ? `***${t('Vacation')}***` : ''}
            </b>
        </>
    );
};
