import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { ReservationFilterContext } from './reservation-filters.context';
type Props = { style?: React.CSSProperties; className?: string };

export const ReservationDateRangeFilterComponent = (props: Props) => {
    const { setFilterState, filterState } = useContext(ReservationFilterContext);

    const handleDateChange = (dates: any) => {
        if (!dates) {
            return;
        }
        const [from, to] = dates;
        setFilterState((x) => {
            return {
                ...x,
                dateRange: {
                    from: from,
                    to: to,
                },
            };
        });
    };

    return (
        <DatePicker.RangePicker
            {...props}
            onChange={handleDateChange}
            value={[filterState.dateRange.from ?? dayjs(), filterState.dateRange.to ?? dayjs()]}
        />
    );
};
