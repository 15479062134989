import { Tag } from 'antd';
import { GetAvailabilitiesUserResponseDto } from '../../api/availability/res/get-availabilities-res.dto';
import { StringUtility } from '../../utils/StringUtility';

type Props = {
    barber: Partial<GetAvailabilitiesUserResponseDto>;
};

export const BarberColorTagComponent = (props: Props) => {
    const userName = props.barber.userName;
    const firstName = props.barber.firstName;
    const lastName = props.barber.lastName;
    if (!firstName || !lastName) {
        return (
            <Tag style={StringUtility.getColorStyles(props.barber.colorString!)}>{userName}</Tag>
        );
    } else {
        return (
            <Tag style={StringUtility.getColorStyles(props.barber.colorString!)}>
                {`${firstName ?? ''} ${lastName ?? ''}`}
            </Tag>
        );
    }
};
