import { Avatar, Badge, Col, Popconfirm, Row, Space, Table, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import type { GetReservationProvidedServiceResponseDto } from '../../../api/reservation/res/get-reservation-provided-service-res.dto';
import type { GetReservationPublicUserResDto } from '../../../api/reservation/res/get-reservation-public-user-res.dto';
import type { GetReservationResDto } from '../../../api/reservation/res/get-reservation-res.dto';
import type { GetReservationUserResDto } from '../../../api/reservation/res/get-reservation-user-res.dto';
import { IntlUtility } from '../../../utils/IntUtility';
import { StringUtility } from '../../../utils/StringUtility';

type Props = {
    dataSource?: GetReservationResDto[];
    onDeleteConfirmed?: (record: GetReservationResDto) => any;
    onEditClicked?: (record: GetReservationResDto) => any;
};
const { Text } = Typography;
export const ReservationTableComponent = (props: Props) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const deleteConfirmed = (record: GetReservationResDto) => {
        props?.onDeleteConfirmed?.(record);
    };
    const onEditClicked = (record: GetReservationResDto) => {
        props?.onEditClicked?.(record);
    };
    const columns = [
        {
            title: t`User`,
            dataIndex: 'user',
            key: 'user',
            render: (user: GetReservationUserResDto) => {
                return (
                    <>
                        <div className="text-center">
                            <Avatar style={StringUtility.getColorStyles(user.colorString)}>
                                {user.userName.charAt(0).toUpperCase()}
                            </Avatar>
                        </div>
                        <div className="text-center">
                            <Text className="fw-bold">{user.userName}</Text>
                        </div>
                    </>
                );
            },
        },
        {
            title: t`Date and Time`,
            dataIndex: 'start',
            key: 'start',
            render(_: undefined, record: GetReservationResDto) {
                return (
                    <>
                        <div>
                            {StringUtility.asAbsoluteLocalDateString(i18n.language, record.from)}
                        </div>
                        <div>
                            <span>
                                {StringUtility.asAbsoluteLocalTimeString(
                                    i18n.language,
                                    record.from
                                )}
                            </span>
                            &nbsp;-&nbsp;
                            <span>
                                {StringUtility.asAbsoluteLocalTimeString(i18n.language, record.to)}
                            </span>
                        </div>
                    </>
                );
            },
        },
        {
            title: t`Public User`,
            dataIndex: 'publicClient',
            key: 'publicClient',
            render: (
                publicClient: GetReservationPublicUserResDto,
                record: GetReservationResDto
            ) => {
                return (
                    <>
                        {publicClient && (
                            <a
                                href={`?userId=${publicClient.id}`}
                                onClick={() => navigate('/users/public-users')}
                            >{`${publicClient?.firstName ?? ''} ${
                                publicClient?.lastName ?? ''
                            }`}</a>
                        )}
                    </>
                );
            },
        },
        {
            title: t`Notes`,
            key: 'notes',
            render: (_: unknown, record: GetReservationResDto) => {
                return (
                    <>
                        <div>{record.name}</div>
                        <b>{record.description}</b>
                    </>
                );
            },
        },
        {
            title: t`Provided Services`,
            dataIndex: 'providedServices',
            key: 'providedServices',
            render: (providedServices: GetReservationProvidedServiceResponseDto[]) =>
                providedServices.map((service) => (
                    <Tag key={service.id}>
                        {`${service.name} ${IntlUtility.formatAsMinute(
                            i18n.language,
                            service.duration
                        )}`}
                        {service.emoji ?? ''}
                    </Tag>
                )),
        },
        {
            title: t`Walk in / No Show`,
            dataIndex: 'isWalkIn',
            key: 'isWalkIn',
            render: (_: unknown, record: GetReservationResDto) => (
                <Space>
                    {record.isWalkIn && <Badge color="pink" text={t`Walk In`}></Badge>}
                    {record.isNoShow && <Badge color="orange" text={t`No show`}></Badge>}
                </Space>
            ),
        },
        {
            title: t`Operation`,
            dataIndex: 'operation',
            key: 'operation',
            render: (_a: unknown, record: GetReservationResDto) => {
                return (
                    <Row>
                        <Col xxl={24} xs={24}>
                            <Typography.Link
                                onClick={() => onEditClicked(record)}
                            >{t`Edit`}</Typography.Link>
                        </Col>
                        <Col xxl={24} xs={24}>
                            <Popconfirm
                                title={t`Sure to delete?`}
                                onConfirm={(_) => deleteConfirmed(record)}
                            >
                                <Typography.Link>{t`Delete`}</Typography.Link>
                            </Popconfirm>
                        </Col>
                    </Row>
                );
            },
        },
    ];

    const dataSource = props.dataSource;

    return (
        <Table
            dataSource={dataSource ?? []}
            loading={!dataSource}
            columns={columns}
            scroll={{ x: 1000 }}
        />
    );
};
